import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../../components/utils/Loading";
import { formattedDate } from "../../../components/formatted/FormattedDateAndTime";
import { getCo2Generated } from "../../../services/container";
import { useTranslation } from "react-i18next";
import { WarningComponent } from "../../../components/WarningComponent";

const StatisticsKeyValuesComponent = ({
  periodHistoryStart,
  periodHistoryStop,
  periodHistoryStartPrevious,
  periodHistoryStopPrevious,
  keywordFilter,
  formatedPeriod,
  numberOfContainers,
  numberOfSite,
}) => {
  const { t, i18n } = useTranslation();
  const [co2Generated, setCo2Generated] = useState(0);
  const [co2GeneratedPrevious, setCo2GeneratedPrevious] = useState(0);
  const [isCo2GeneratedPreviousPeriod, setIsCo2GeneratedPreviousPeriod] =
    useState(0);
  const [percentageCo2Generated, setPercentageCo2Generated] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [helpCo2Generated, setHelpCo2Generated] = useState(false);
  const [helpCo2GeneratedPrevious, setHelpCo2GeneratedPrevious] =
    useState(false);
  const [helpCo2GeneratedComparison, setHelpCo2GeneratedComparison] =
    useState(false);
  const [isRequiredWarning, setIsRequiredWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    setIsLoading(true);
    axios
      .all([
        getCo2Generated({
          periodStart: periodHistoryStart,
          periodStop: periodHistoryStop,
          keywordFilter: JSON.stringify(keywordFilter.value),
        }),
        getCo2Generated({
          periodStart: periodHistoryStartPrevious,
          periodStop: periodHistoryStopPrevious,
          keywordFilter: JSON.stringify(keywordFilter.value),
        }),
      ])
      .then(
        axios.spread((responseCo2Generated, responseCo2GeneratedPrevious) => {
          if (
            responseCo2Generated.status === 200 &&
            responseCo2GeneratedPrevious.status === 200
          ) {
            setCo2Generated(responseCo2Generated.data.co2Generated);
            setCo2GeneratedPrevious(
              responseCo2GeneratedPrevious.data.co2Generated,
            );

            setPercentageCo2Generated(
              Math.round(
                ((responseCo2Generated.data.co2Generated -
                  responseCo2GeneratedPrevious.data.co2Generated) /
                  responseCo2GeneratedPrevious.data.co2Generated) *
                  100,
              ),
            );

            setIsCo2GeneratedPreviousPeriod(
              responseCo2GeneratedPrevious.data.co2Generated > 0,
            );

            const errorMessage =
              i18n.language === "en"
                ? "Destination sites and/or densities are missing. Please configure them in your address and container settings."
                : "Sites de destination et/ou densités manquants. Configurez les dans vos pages.";

            if (
              responseCo2Generated.data.error ||
              responseCo2GeneratedPrevious.data.error
            ) {
              setErrorMessage(errorMessage);
            }

            setIsLoading(false);
          }
        }),
      )
      .catch((err) => {
        if (err.response.status === 400) {
          if (err.response.data.error === "No containers completed") {
            const errorMessage =
              i18n.language === "en"
                ? "Configure destination addresses and densities in your address and container settings"
                : "Configurez les adresses de destination et les densités sur vos pages.";
            setErrorMessage(errorMessage);
            setIsRequiredWarning(true);
            setIsLoading(false);
          }
        }
      });
  };

  useEffect(
    () => {
      fetchData();
    },
    // eslint-disable-next-line
    [
      keywordFilter,
      periodHistoryStart,
      periodHistoryStop,
      periodHistoryStartPrevious,
      periodHistoryStopPrevious,
    ],
  );

  return (
    <div>
      {!isLoading ? (
        <section className="bg-white p-3 rounded-lg my-2">
          <h4 className="font-semibold">
            {t("Summary of period")} : <span>{formatedPeriod} </span>
            <span>
              | {numberOfSite} site{numberOfSite > 1 ? "s " : " "}
            </span>
            <span>
              | {numberOfContainers} {t("Container")}
              {numberOfContainers > 1 ? "s" : ""}
            </span>
          </h4>
          {isRequiredWarning || errorMessage ? (
            <article className="w-full grid justify-items-center p-5">
              <WarningComponent required={true} message={errorMessage} />
            </article>
          ) : (
            <article className="flex flex-row w-full p-4 gap-6">
              <div className="flex flex-col w-2/4 p-3 bg-[#FFE2E5] rounded-lg">
                <div className="grid justify-items-center rounded-full bg-[#FA5A7D] h-8 w-8">
                  <i
                    className="fas fa-cloud text-white my-auto"
                    style={{ marginTop: "2px" }}
                  ></i>
                </div>
                <h3 className="font-bold mt-2">
                  {co2Generated === 0 ? "-" : Math.round(co2Generated)}{" "}
                  kg&thinsp;
                </h3>
                <div className="flex">
                  <span className="font-bolder text-blue mr-2">
                    CO
                    <sub style={{ fontWeight: "normal", fontSize: "14px" }}>
                      2
                    </sub>
                  </span>
                  <span>{t("produced")}</span>
                  <i
                    className="fas fa-info-circle text-gainsboro text-xs ml-1"
                    onMouseOver={() => setHelpCo2Generated(true)}
                    onMouseOut={() => setHelpCo2Generated(false)}
                  ></i>
                </div>
                {helpCo2Generated ? (
                  <div
                    className="absolute bg-yellow text-white top-96 text-xs p-2 rounded"
                    style={{ left: "18rem" }}
                  >
                    {i18n.language === "fr" && "Du "}
                    {formattedDate(periodHistoryStart, i18n.language)}{" "}
                    {i18n.language === "fr" ? "à" : "to"}{" "}
                    {formattedDate(periodHistoryStop, i18n.language)}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col w-2/4 p-3 bg-[#DCFCE7] rounded-lg">
                <div className="grid justify-items-center rounded-full bg-[#3CD856] h-8 w-8">
                  <i
                    className="fas fa-cloud text-white my-auto"
                    style={{ marginTop: "2px" }}
                  ></i>
                </div>
                <h3 className="font-bold mt-2">
                  {co2GeneratedPrevious === 0
                    ? "-"
                    : Math.round(co2GeneratedPrevious)}{" "}
                  kg&thinsp;
                </h3>
                <div className="flex">
                  <span className="font-bolder text-blue mr-2">
                    CO
                    <sub style={{ fontWeight: "normal", fontSize: "14px" }}>
                      2
                    </sub>
                  </span>
                  <span>{t("produced during the previous period")}</span>
                  <i
                    className="fas fa-info-circle text-gainsboro text-xs ml-1"
                    onMouseOver={() => setHelpCo2GeneratedPrevious(true)}
                    onMouseOut={() => setHelpCo2GeneratedPrevious(false)}
                  ></i>
                </div>
                {helpCo2GeneratedPrevious ? (
                  <div
                    className="absolute bg-yellow text-white top-96 text-xs p-2 rounded"
                    style={{ left: "52rem" }}
                  >
                    {i18n.language === "fr" && "Du "}
                    {formattedDate(
                      periodHistoryStartPrevious,
                      i18n.language,
                    )}{" "}
                    {i18n.language === "fr" ? "au" : "to"}{" "}
                    {formattedDate(periodHistoryStopPrevious, i18n.language)}
                  </div>
                ) : null}
              </div>
              {isCo2GeneratedPreviousPeriod ? (
                <div className="flex flex-col w-2/4 p-3 bg-[#F3E8FF] rounded-lg">
                  <div className="grid justify-items-center rounded-full bg-[#BF83FF] h-8 w-8">
                    <i
                      className="fas fa-chart-bar text-white my-auto"
                      style={{ marginTop: "2px" }}
                    ></i>
                  </div>
                  <h3 className="font-bold mt-2">
                    {percentageCo2Generated > 0 ? "+" : null}
                    {percentageCo2Generated}%
                  </h3>
                  <div className="flex">
                    {i18n.language === "fr" ? (
                      <>
                        <span>Variations de</span>
                        <span className="font-bolder text-blue mr-2 ml-2">
                          CO
                          <sub
                            style={{ fontWeight: "normal", fontSize: "14px" }}
                          >
                            2
                          </sub>
                        </span>
                        <span>générés</span>
                      </>
                    ) : (
                      <>
                        <span className="font-bolder text-blue mr-2">
                          CO
                          <sub
                            style={{ fontWeight: "normal", fontSize: "14px" }}
                          >
                            2
                          </sub>
                        </span>
                        <span>produced variations</span>
                      </>
                    )}
                    <i
                      className="fas fa-info-circle text-gainsboro text-xs ml-1"
                      onMouseOver={() => setHelpCo2GeneratedComparison(true)}
                      onMouseOut={() => setHelpCo2GeneratedComparison(false)}
                    ></i>
                    {helpCo2GeneratedComparison && (
                      <div
                        className="absolute bg-yellow text-white top-96 text-xs p-2 rounded"
                        style={{ right: "10rem" }}
                      >
                        {t("Comparison with the previous period")}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col w-2/4 p-3 text-center bg-[#F3E8FF] rounded-lg">
                  <i className="fas fa-exclamation-triangle text-xl mt-3"></i>
                  <span className="text-center">
                    {t("Not enough data on the chosen period")}.
                  </span>
                </div>
              )}
            </article>
          )}
        </section>
      ) : (
        <div className="my-2">
          <Loading height={"35vh"} />
        </div>
      )}
    </div>
  );
};

export default StatisticsKeyValuesComponent;
