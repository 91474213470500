import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getNotifications } from "../services/notification";

const initialState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchNotification = createAsyncThunk("notification", async () => {
  const now = new Date();
  const twoHoursAgo = new Date(now.getTime() - 2 * 60 * 60 * 1000);
  const response = await getNotifications();
  if (response?.data?.notifications) {
    const recentAnomalies = response?.data?.notifications.filter((item) => {
      const anomalyDate = new Date(item.date);
      return anomalyDate >= twoHoursAgo && anomalyDate <= now;
    });
    return recentAnomalies;
  } else {
    return [];
  }
});

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotification.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNotification.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchNotification.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
