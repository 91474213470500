import { useEffect } from "react";
import * as echarts from "echarts";
import { getWasteCategoryColor } from "../../components/utils/WasteCategoryColor";

export const AnomalyPieChart = ({
  dataChart,
  countAnomalies,
  wasteCategory,
}) => {
  useEffect(() => {
    const chartAnomaly = document.getElementById("anomaly-chart");
    const anomalyChart = echarts.init(chartAnomaly);

    const data = dataChart.map((value) => ({
      value: ((value.data[0] * 100) / countAnomalies).toFixed(1),
      name: value.label,
      itemStyle: {
        color: getWasteCategoryColor(wasteCategory, value),
      },
    }));

    let option;
    option = {
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          type: "pie",
          radius: ["60%", "100%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              formatter: "{c}%",
            },
          },
          labelLine: {
            show: false,
          },
          data: data,
        },
      ],
    };
    option && anomalyChart.setOption(option);
  }, [dataChart, wasteCategory, countAnomalies]);

  return (
    <div
      id="anomaly-chart"
      style={{
        height: 180,
        width: 180,
      }}
    ></div>
  );
};
