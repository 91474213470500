/*eslint-disable*/
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { checkIfMobile } from "../components/mobileHelpers";
import Cookies from "js-cookie";
import { FaCheckCircle, FaTimesCircle, FaClock } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faFlask,
  faSkull,
  faWrench,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { capitalizeFirstLetter } from "../components/utils/Date";
import { formattedLevelBattery } from "../components/formatted/FormattedText";
import { formattedNetworkQuality } from "../components/formatted/FormattedText";
import { getShortIdPart } from "../components/formatted/FormattedText";

const AppInstallationRow = ({ item }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [mostRecentLogDetails, setMostRecentLogDetails] = useState({});
  const history = useHistory();

  function formatNextWakeupDatetime(nextWakeupDatetime) {
    const currentDate = new Date();
    const wakeupDate = new Date(nextWakeupDatetime);

    // Options pour formater l'heure
    const optionsTime = { hour: "2-digit", minute: "2-digit" };
    const optionsDate = { weekday: "long" }; // Affiche le jour de la semaine

    // Vérifie si la date de réveil est le jour actuel
    if (wakeupDate.toDateString() === currentDate.toDateString()) {
      // Si c'est aujourd'hui, on retourne juste l'heure
      return wakeupDate.toLocaleTimeString([], optionsTime);
    } else {
      // Sinon, on retourne le jour et l'heure
      const dayName = wakeupDate.toLocaleDateString("en-US", optionsDate);
      return `${dayName}, ${wakeupDate.toLocaleTimeString([], optionsTime)}`;
    }
  }

  const fetchCycleLogAndImageList = async () => {
    await axios
      .all([
        axios.get(
          `${API}azurecyclelogandimage/${item._id.replace("_r", "").replace("_l", "")}`,
          {
            headers: {
              authorization: "Bearer " + token,
            },
            params: {
              numberOfDaysBack: 1,
            },
          },
        ),
      ])
      .then(
        axios.spread((response) => {
          if (response.status === 200) {
            setFileList(
              response.data
                .sort((a, b) => {
                  return b.name.localeCompare(a.name); // Tri décroissant
                })
                .slice(0, 5),
            );
          }
        }),
      );
  };

  const fetchCycleLogDetails = async () => {
    const recentLogEntry = fileList
      .filter((item) => item.log) // Filtrer pour garder uniquement les éléments avec log: true
      .reduce((latest, current) => {
        return new Date(latest.matchingCrontabDateTime) >
          new Date(current.matchingCrontabDateTime)
          ? latest
          : current; // Comparer les dates et garder le plus récent
      }, fileList[0]);

    if (recentLogEntry.log === true) {
      await axios
        .all([
          axios.get(
            `${API}akanthascameralogdetails/${item._id.replace("_r", "").replace("_l", "")}`,
            {
              headers: {
                authorization: "Bearer " + token,
              },
              params: {
                filename: recentLogEntry.name,
              },
            },
          ),
        ])
        .then(
          axios.spread((response) => {
            if (response.status === 200) {
              setMostRecentLogDetails(response.data);
            }
          }),
        );
    }
  };

  useEffect(() => {
    fetchCycleLogAndImageList();
  }, [item._id]);

  useEffect(() => {
    fetchCycleLogDetails();
  }, [fileList]);

  const renderSensorCountIcon = (status) => {
    switch (status) {
      case "working":
        return (
          <>
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "12px", color: "#7ED958" }}
            />
          </>
        );
      case "test":
        return (
          <>
            <FontAwesomeIcon
              icon={faFlask}
              style={{ fontSize: "12px", color: "blue" }}
            />
          </>
        );
      case "broken":
        return (
          <>
            <FontAwesomeIcon
              icon={faWrench}
              style={{ fontSize: "12px", color: "orange" }}
            />
          </>
        );
      case "dead":
        return (
          <>
            <FontAwesomeIcon
              icon={faSkull}
              style={{ fontSize: "12px", color: "black" }}
            />
          </>
        );
      case "undefined":
        return (
          <>
            <FontAwesomeIcon
              icon={faQuestion}
              style={{ fontSize: "12px", color: "grey" }}
            />
          </>
        );
      case "":
      case undefined:
      default:
        return (
          <>
            <span>?</span>
          </>
        );
    }
  };

  useEffect(() => {
    setIsMobile(checkIfMobile());

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");

  const handleStart = (id) => {
    history.push(`/app/installationdetails/${id}`);
  };

  if (!isMobile) {
    return (
      <div className="mobileapp-container">
        <h1>Cette page est optimisée pour les appareils mobiles</h1>
        <p>
          Veuillez accéder à cette page depuis un appareil mobile pour une
          meilleure expérience.
        </p>
      </div>
    );
  }

  return (
    <div
      key={item._id}
      className="installation-row"
      onClick={() => {
        if (item.status !== "working") {
          handleStart(item._id);
        }
      }}
      style={{ cursor: item.status === "working" ? "not-allowed" : "pointer" }}
    >
      <div className="installation-row-header">
        <div className="installation-left">
          <div className="installation-details">
            <h3>{"ak_" + getShortIdPart(item._id)}</h3>

            {Object.keys(mostRecentLogDetails).length !== 0 ? (
              <p
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  {formattedLevelBattery(mostRecentLogDetails.battery)}
                  <span style={{ marginLeft: "-4px" }}>
                    {mostRecentLogDetails.battery !== undefined &&
                      Math.trunc(mostRecentLogDetails.battery) + "%"}
                  </span>
                </span>

                <span
                  style={{
                    marginLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {formattedNetworkQuality()}
                  <span style={{ marginLeft: "4px" }}>
                    {mostRecentLogDetails.networkQuality},{" "}
                    {mostRecentLogDetails.operator}
                  </span>
                </span>
              </p>
            ) : (
              <p
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                No recent log loaded
              </p>
            )}

            <div className="image-status">
              {fileList.length === 0
                ? // Afficher des icônes en gris pour le chargement si fileList est vide

                  Array.from({ length: 3 }).map((_, index) => (
                    <div key={index} className="image-status-item">
                      <FaClock
                        className="status-icon loading"
                        style={{ color: "gray" }}
                      />
                      <p style={{ fontSize: "12px" }}>.........</p>
                    </div>
                  ))
                : fileList
                    .slice(0, 3)
                    .reverse()
                    .map((file, index) => {
                      // Déterminer l'icône à afficher
                      let statusIcon;
                      if (file.log) {
                        statusIcon = (
                          <FaCheckCircle className="status-icon received" />
                        );
                      } else {
                        statusIcon = (
                          <FaTimesCircle className="status-icon missing" />
                        );
                      }

                      // Retourner la div pour chaque fichier
                      return (
                        <div key={index} className="image-status-item">
                          {statusIcon}
                          <p style={{ fontSize: "12px" }}>
                            {new Date(
                              file.name?.slice(0, 19).replaceAll("_", ":") +
                                ".000Z",
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </p>
                        </div>
                      );
                    })}
              <div className="image-status-item next">
                <FaClock className="status-icon next" />
                <p style={{ fontSize: "12px" }}>
                  {mostRecentLogDetails.nextWakeupDatetime &&
                    formatNextWakeupDatetime(
                      mostRecentLogDetails.nextWakeupDatetime,
                    )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="installation-right">
          <p>
            {renderSensorCountIcon(item.status)}{" "}
            {capitalizeFirstLetter(item.status)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AppInstallationRow;
