import React, { useState, useEffect, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import {
  formattedFillingDelivery,
  formattedFillingDeliveryColor,
  formattedTypeContainer,
  formattedWasteColor,
  truncateText,
} from "../../components/formatted/FormattedText";
import { currentMonthName } from "../../components/utils/Date";
import MultipleLineAndBarChart from "../../components/charts/MultipleLineAndBarChart";
import { VolumePieChart } from "../../components/charts/VolumePieChart";
import { AnomalyPieChart } from "../../components/charts/AnomalyPieChart";
import { sinceDate } from "../../components/formatted/FormattedDateAndTime";
import CharacterisationSvg from "../../components/utils/CharacterisationSvg";
import Cookie from "js-cookie";
import { DashboardCo2 } from "./DashboardCo2";
import { getWasteCategoryColor } from "../../components/utils/WasteCategoryColor";
import CustomPopover from "../../components/popover/CustomPopover";
import containerIcon from "../../static/assets/ContainerIcon.jpg";
import { useTranslation } from "react-i18next";
import {
  countAnomaliesData,
  getContainersList,
  getListRotationPeriods,
  totalVolumeCharacterization,
} from "../../services/container";
import SkeletonLoader from "../../components/utils/SkeletonLoader";

export const DashbordIndicators = ({
  countContainers,
  setCountContainers,
  countAnomalies,
  setCountAnomalies,
  setDataMaps,
  arrayFillingDelivery,
  setArrayFillingDelivery,
  setFillingRateDelivery,
  isLoading,
  setIsLoading,
  meanFilling,
  setMeanFilling,
  totalVolume,
  setTotalVolume,
  totalVolumeUsed,
  setTotalVolumeUsed,
  typeContainersDelivery,
  setTypeContainersDelivery,
  wasteErrorDelivery,
  setWasteErrorDelivery,
  wasteDelivery,
  setWasteDelivery,
  wasteCategory,
  dataMaps,
  characterization,
  setCharacterization,
}) => {
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookie.get("userToken");
  const { t, i18n } = useTranslation();

  const [helpFilling, setHelpFilling] = useState(false);
  const [helpAnomaly, setHelpAnomaly] = useState(false);
  const [helpWaste, setHelpWaste] = useState(false);
  const [countCharacterization, setCountCharacterization] = useState(0);
  const [totalCharacterizationVolume, setTotalCharacterizationVolume] =
    useState(0);
  const [licenseEndDate, setLicenseEndDate] = useState(null);
  const [characterizationCredits, setCharacterizationCredits] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedWaste, setSelectedWaste] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [loadFillingDelivery, setLoadFillingDelivery] = useState(true);
  const [loadCharacterizations, setLoadCharacterizations] = useState(true);
  const [loadAnomalies, setLoadAnomalies] = useState(true);
  const history = useHistory();
  const open = Boolean(anchorEl);

  const handleAddressRowClick = (addressName) => {
    history.push({
      pathname: `/containers`,
      state: {
        addressF: addressName,
      },
    });
  };

  const handlePopoverOpen = (event, waste) => {
    setAnchorEl(event.currentTarget);
    setSelectedWaste(waste);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedWaste(null);
  };

  useEffect(
    () => {
      if (dataMaps.length > 0) {
        for (const dataMap of dataMaps) {
          if (dataMap.characterization) {
            setCharacterization(true);
          }
        }
      }
    },
    // eslint-disable-next-line
    [dataMaps],
  );

  const fetchContainerList = useCallback(
    async () => {
      getContainersList({
        page: null,
        limit: null,
        fieldSort: "filling_level",
        orderSort: "-1",
        typeFillingFilter: "all",
      }).then((response) => {
        if (response.status === 200) {
          setArrayFillingDelivery(response.data.arrayFillingDelivery);
          setFillingRateDelivery(response.data.arrayFillingRateDelivery);
          setCountContainers(response.data.countContainers);
          setLoadFillingDelivery(false);
        }
      });
    },
    // eslint-disable-next-line
    [],
  );

  const fetchCharacterization = useCallback(
    async () => {
      await totalVolumeCharacterization({
        periodStart: 0,
      }).then((response) => {
        if (response.status === 200) {
          setTotalCharacterizationVolume(
            response.data.totalVolumeCharacterization,
          );
        }
      });
      await getListRotationPeriods(
        {
          periodMaterialStart: 0,
        },
        null,
      ).then((response) => {
        if (response.status === 200) {
          setCountCharacterization(
            totalCharacterizations(response.data.containersPeriods),
          );
        }
      });
      setLoadCharacterizations(false);
    },
    // eslint-disable-next-line
    [],
  );

  const fetchAnomalies = useCallback(
    async () => {
      const stopPeriod = new Date();
      const startPeriod = new Date(
        stopPeriod.getFullYear(),
        stopPeriod.getMonth(),
        1,
      );
      await countAnomaliesData({
        periodStart: startPeriod.toISOString(),
        periodStop: stopPeriod.toISOString(),
      }).then((response) => {
        if (response.status === 200) {
          setCountAnomalies(response.data.countAnomalies);
          setWasteErrorDelivery(response.data.wasteErrorDelivery);
        }
      });
      setLoadAnomalies(false);
    },
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    fetchAnomalies();
  }, [fetchAnomalies]);

  const fetchData = useCallback(
    () => {
      axios
        .all([
          axios.get(`${API}dashboard`, {
            headers: { authorization: `Bearer ${token}` },
          }),
          axios.get(`${API}settings`, {
            headers: { authorization: `Bearer ${token}` },
          }),
        ])
        .then(
          axios.spread((response, responseSettings) => {
            if (response.status === 200 && responseSettings.status === 200) {
              setDataMaps(response.data.lastDataContainers);

              const groupedByAddressId =
                response.data.lastDataContainers.reduce((acc, container) => {
                  const { addressId, addressName } = container;

                  if (!acc.has(addressId)) {
                    acc.set(addressId, { addressName, count: 1 });
                  } else {
                    acc.get(addressId).count += 1;
                  }

                  return acc;
                }, new Map());

              setAddresses([...groupedByAddressId.values()]);
              setMeanFilling(response.data.meanFilling);
              setTotalVolume(response.data.totalVolume);
              setTotalVolumeUsed(response.data.totalVolumeUsed);
              setTypeContainersDelivery(response.data.typeContainersDelivery);
              setWasteDelivery(response.data.wasteDelivery);
              setCharacterizationCredits(
                responseSettings.data.settings.characterizationCredits,
              );
              setLicenseEndDate(responseSettings.data.settings.licenseEndDate);
              setIsLoading(false);
            }
          }),
        );
    },
    // eslint-disable-next-line
    [API, token],
  );

  useEffect(() => {
    fetchData();
    fetchContainerList();
  }, [fetchData, fetchContainerList]);

  useEffect(() => {
    if (characterization) {
      fetchCharacterization();
    }
  }, [characterization, fetchCharacterization]);

  const labelsChartWaste = wasteDelivery.map((waste) => waste.wasteName);
  const dataChartWaste = wasteDelivery.map((waste) => waste.volume);

  const datasetsWasteErrorDelivery = [];
  let datasetsFillingDevlery = [];

  arrayFillingDelivery.map((fillingDelivery) => {
    datasetsFillingDevlery.push({
      label: fillingDelivery.label,
      data: [fillingDelivery.value],
      backgroundColor: [formattedFillingDeliveryColor(fillingDelivery.type)],
    });

    return null;
  });

  Object.keys(wasteErrorDelivery).forEach((waste) => {
    datasetsWasteErrorDelivery.push({
      label: waste,
      data: [wasteErrorDelivery[waste]],
      backgroundColor: [waste ? formattedWasteColor(waste) : "#6CB647"],
    });
    return null;
  });

  function totalCharacterizations(containersPeriods) {
    let sum = 0;
    for (const containerPeriod of containersPeriods) {
      sum = sum + containerPeriod.length;
    }
    return sum;
  }

  return (
    <section>
      <div className="flex flex-col text-end mx-3 text-grey">
        <div>
          <span className="text-cyan font-semibold">
            {licenseEndDate
              ? sinceDate(new Date(), licenseEndDate, i18n.language)
              : "-"}
          </span>
          <span className="ml-1">{t("license left")}</span>
        </div>
        <div>
          <span className="text-cyan font-semibold">
            {characterizationCredits}
          </span>
          <span className="ml-1">{t("characterization credits left")}</span>
        </div>
      </div>

      <div className="flex flex-wrap w-full justify-between m-auto">
        {/* Containers information */}
        <section className="dashboard-card">
          <article className="dashboard-card-top">
            <div className="dashboard-indicator">
              <article>
                <div className="flex flex-col ml-3 mt-1">
                  <div className="flex flex-row">
                    <h3
                      className="text-blush text-3xl font-semibold"
                      style={{ marginTop: -2 }}
                    >
                      {countContainers}
                    </h3>
                    <h5 className="font-semibold text-lg mt-1.5 ml-2">
                      {t(
                        `area/container${countContainers > 1 ? "_plural" : ""}`,
                      )}
                    </h5>
                  </div>
                  <h5
                    className="font-semibold text-lg"
                    style={{ marginTop: -13 }}
                  >
                    {t(
                      `being monitored${countContainers > 1 ? "_plural" : ""}`,
                    )}
                  </h5>
                </div>
              </article>
            </div>
            <i
              className="fas fa-info-circle text-gainsboro"
              style={{ marginTop: "10px", marginRight: "-5px" }}
              onMouseOver={() => setHelpFilling(true)}
              onMouseOut={() => setHelpFilling(false)}
            ></i>
          </article>
          {loadFillingDelivery ? (
            <SkeletonLoader />
          ) : (
            <>
              <article className="dashboard-card-content">
                <div
                  className="dashboard-chart"
                  style={{ maxHeight: "30px", marginTop: "30px" }}
                >
                  <MultipleLineAndBarChart
                    stackedChart={true}
                    type="bar"
                    borderWidthChart={0}
                    labelsChart={[""]}
                    aspectRatioChart={12}
                    datasetsChart={datasetsFillingDevlery}
                    indexAxisChart="y"
                    maintainAspectRatioChart={false}
                    displayGrid={false}
                    displayTicks={false}
                    xAxisType={"linear"}
                    reverseChart={true}
                    displayLegend={false}
                    displayTooltip={false}
                    messageLabelTooltip=""
                    unitValueChart=""
                    maxYAxis={null}
                    height={10}
                  />
                </div>
                <section>
                  <section className="dashboard-filling">
                    {arrayFillingDelivery.map((typeFilling, index) => {
                      return (
                        <article
                          className="dashboard-filling-delivery"
                          key={typeFilling._id}
                        >
                          <div
                            className="dashboard-filling-color"
                            style={{
                              backgroundColor: `${formattedFillingDeliveryColor(typeFilling.type)}`,
                            }}
                          ></div>
                          <span>{typeFilling.value}</span>
                          <Link
                            to={{
                              pathname: `/containers`,
                              state: {
                                typeFilling: typeFilling.type,
                              },
                            }}
                          >
                            <span className="dashboard-filling-type dashboard-text-underline">
                              {formattedFillingDelivery(
                                typeFilling.type,
                                "filling",
                                typeFilling.value,
                              )}
                            </span>
                          </Link>
                        </article>
                      );
                    })}
                  </section>
                </section>
              </article>

              <article className="dashboard-card-footer">
                {typeContainersDelivery.map((typeContainer, index) => {
                  return (
                    <div key={`type-container-delivery-${typeContainer._id}`}>
                      <span className="text-blush font-bold">
                        {(
                          (typeContainer.count * 100) /
                          countContainers
                        ).toFixed(1)}
                        %
                      </span>{" "}
                      {formattedTypeContainer(typeContainer._id)}
                    </div>
                  );
                })}
              </article>

              {helpFilling ? (
                <article className="modal-item-key-help">
                  <ul style={{ marginLeft: 10 }}>
                    <li>
                      {t("Critical")} :{" "}
                      {t(
                        "Number of containers that have surpassed their critical limit",
                      )}
                      .
                    </li>
                    <li>
                      {t("In progress")} :{" "}
                      {t("Number of containers below the critical limit")}.
                    </li>
                    <li>
                      {t("Empty")} :{" "}
                      {t("Number of containers with a fill rate of 0%")}.
                    </li>
                  </ul>
                </article>
              ) : null}
            </>
          )}
        </section>

        {/* Anomalies information */}
        <section className="dashboard-card">
          <article className="dashboard-card-top">
            <div className="dashboard-indicator">
              <article>
                <i
                  className="fas fa-recycle "
                  style={{
                    fontSize: "55px",
                    color: "var(--akanthas-cyan)",
                    marginLeft: -10,
                    marginTop: 8,
                  }}
                ></i>
                <div className="flex flex-col ml-3 mt-1">
                  <div className="flex flex-row">
                    <h3
                      className="text-blush text-3xl font-semibold"
                      style={{ marginTop: -2 }}
                    >
                      {countAnomalies}
                    </h3>
                    <h5 className="font-semibold text-lg mt-1.5 ml-2">
                      {t("sorting errors")}
                    </h5>
                  </div>
                  <h5
                    className="font-semibold text-lg"
                    style={{ marginTop: -13 }}
                  >
                    {t("on")}{" "}
                    <span className="text-blush">
                      {currentMonthName(i18n.language)}
                    </span>
                  </h5>
                </div>
              </article>
            </div>
            <i
              className="fas fa-info-circle text-gainsboro"
              style={{ marginTop: "10px", marginRight: "-5px" }}
              onMouseOver={() => setHelpAnomaly(true)}
              onMouseOut={() => setHelpAnomaly(false)}
            ></i>
          </article>
          {loadAnomalies ? (
            <SkeletonLoader />
          ) : (
            <>
              <article className="dashboard-card-content">
                <div className="flex">
                  <div style={{ flex: "1" }}>
                    {datasetsWasteErrorDelivery.length > 0 &&
                      wasteCategory?.length > 0 && (
                        <AnomalyPieChart
                          dataChart={datasetsWasteErrorDelivery}
                          countAnomalies={countAnomalies}
                          wasteCategory={wasteCategory}
                        />
                      )}
                  </div>
                  <div
                    className="flex flex-col"
                    style={{
                      marginLeft: "5%",
                      width: "auto",
                      flex: "1",
                      overflow: "hidden",
                    }}
                  >
                    {datasetsWasteErrorDelivery?.map((waste, index) => {
                      return (
                        <Link
                          to={{
                            pathname: "/activity",
                            state: {
                              displayCollecte: false,
                              displayAnomalieHistory: true,
                              keywordFilter: {
                                value: [
                                  { key: waste, cat: "Flux", type: "waste" },
                                ],
                              },
                            },
                          }}
                          key={`${waste}-delivery-${waste._id}`}
                          onMouseEnter={(event) =>
                            handlePopoverOpen(event, waste?.label)
                          }
                          onMouseLeave={handlePopoverClose}
                          style={{
                            width: "100%",
                          }}
                        >
                          <div className="flex relative flex-row items-center justify-between w-ful">
                            <span
                              style={{
                                width: "9px",
                                height: "9px",
                                backgroundColor: `${getWasteCategoryColor(wasteCategory, waste)}`,
                                marginTop: 5,
                              }}
                            ></span>
                            <div
                              style={{
                                flex: "1",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                className="underline"
                                style={{ fontSize: "11.5px" }}
                              >
                                {waste?.label}
                              </span>
                              <CustomPopover
                                open={open}
                                anchorEl={anchorEl}
                                content={selectedWaste}
                                onClose={handlePopoverClose}
                              />
                            </div>
                            <div style={{ width: 45, textAlign: "right" }}>
                              <span
                                className="ml-1"
                                style={{
                                  fontSize: "11.5px",
                                  marginTop: 4,
                                }}
                              >
                                (
                                {(
                                  (waste.data[0] * 100) /
                                  countAnomalies
                                ).toFixed(1)}
                                %)
                              </span>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </article>

              <article className="dashboard-card-footer">
                <Link
                  to={{
                    pathname: "/statistics",
                    state: {
                      activateCollectionField: false,
                      activateQualityField: true,
                      activateCostField: false,
                      activateCarbonField: false,
                    },
                  }}
                >
                  <div className="dashboard-text-underline">
                    {t("View details")}
                  </div>
                </Link>
              </article>

              {helpAnomaly ? (
                <article className="modal-item-key-help">
                  {t(
                    "Number of sorting errors detected across all containers during",
                  )}{" "}
                  {currentMonthName(i18n.language)}.
                </article>
              ) : null}
            </>
          )}
        </section>

        {/* CO2 information */}
        <DashboardCo2 />

        {/* Characterization information */}
        <section className="dashboard-card">
          <article className="dashboard-card-top">
            <div className="dashboard-indicator">
              <article>
                <div style={{ marginTop: 5, marginLeft: -10 }}>
                  <CharacterisationSvg
                    width={53}
                    height={63}
                    widthViewBox={22}
                    heightViewBox={28}
                  />
                </div>
                <div className="flex flex-col ml-3 mt-1">
                  <div className="flex flex-row">
                    <h3
                      className="text-blush text-3xl font-semibold"
                      style={{ marginTop: -2 }}
                    >
                      {countCharacterization}
                    </h3>
                    <h5 className="font-semibold text-lg mt-1.5 ml-2">
                      {t("Characterization").toLowerCase()}
                      {countCharacterization > 1 && "s"}
                    </h5>
                  </div>
                  <h5
                    className="font-semibold text-lg"
                    style={{ marginTop: -13 }}
                  >
                    {t("during")}{" "}
                    <span className="text-blush">
                      {currentMonthName(i18n.language)}
                    </span>
                  </h5>
                </div>
              </article>
            </div>
          </article>
          {loadCharacterizations && characterization ? (
            <SkeletonLoader />
          ) : (
            <>
              <div>
                {!characterization ? (
                  <h4 className="text-cyan font-semibold">
                    <span className="text-5xl">0</span>
                    <span className="text-3xl"> {t("Container")}</span>
                  </h4>
                ) : (
                  <h4 className="text-cyan font-semibold text-5xl">
                    {Math.round(totalCharacterizationVolume)} m<sup>3</sup>
                  </h4>
                )}
                {characterization && (
                  <span className="text-grey">{t("of waste analyzed")}</span>
                )}
              </div>

              <div className="dashboard-card-footer">
                <Link
                  to={{
                    pathname: "/containers",
                  }}
                >
                  <div className="dashboard-text-underline">
                    {t("View details")}
                  </div>
                </Link>
              </div>
            </>
          )}
        </section>

        {/* Waste information */}
        <section className="dashboard-card">
          <article className="dashboard-card-top">
            <div className="dashboard-indicator">
              <article>
                <i
                  className="fas fa-layer-group "
                  style={{
                    fontSize: "55px",
                    color: "var(--akanthas-cyan)",
                    marginLeft: -10,
                    marginTop: 8,
                  }}
                ></i>
                <div className="flex flex-col ml-3 mt-1">
                  <div className="flex flex-row">
                    <h3
                      className="text-blush text-3xl font-semibold"
                      style={{ marginTop: -2 }}
                    >
                      {Math.round(totalVolumeUsed)} m<sup>3</sup>
                    </h3>
                    <h5
                      className="font-semibold text-lg ml-2"
                      style={{ marginTop: 7.5 }}
                    >
                      {t("of waste")}
                    </h5>
                  </div>
                  <h5
                    className="font-semibold text-blue text-lg"
                    style={{ marginTop: -13 }}
                  >
                    {t("currently on site")}
                  </h5>
                </div>
              </article>
            </div>
            <i
              className="fas fa-info-circle text-gainsboro"
              style={{ marginTop: "10px", marginRight: "-5px" }}
              onMouseOver={() => setHelpWaste(true)}
              onMouseOut={() => setHelpWaste(false)}
            ></i>
          </article>
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            <>
              <article className="dashboard-card-content mt-2">
                <section>
                  <div style={{ maxHeight: "90%", marginRight: 5 }}>
                    {dataChartWaste.length > 0 &&
                    labelsChartWaste.length > 0 &&
                    wasteCategory.length > 0 ? (
                      <VolumePieChart
                        dataChart={dataChartWaste}
                        labelsChartWaste={labelsChartWaste}
                        totalVolumeUsed={totalVolumeUsed}
                        wasteCategory={wasteCategory}
                      />
                    ) : null}
                  </div>
                  <section className="flex flex-col w-1/2">
                    <div className="w-1/2 m-auto">
                      <h6>
                        <span className="text-blush font-bold">
                          {Math.round((totalVolumeUsed * 100) / totalVolume)}%
                        </span>{" "}
                        {t("container usage")}
                      </h6>
                    </div>
                  </section>
                </section>
              </article>
              <article className="dashboard-card-footer">
                <Link
                  to={{
                    pathname: "/containers",
                  }}
                >
                  <div className="dashboard-text-underline">
                    {t("View details")}
                  </div>
                </Link>
              </article>
              {helpWaste ? (
                <article className="modal-item-key-help">
                  {t(
                    "Total volume of waste produced and present on site currently",
                  )}
                  .
                </article>
              ) : null}
            </>
          )}
        </section>

        <section className="dashboard-card">
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            <article className="dashboard-card-top">
              <div className="address-container" style={{ marginTop: "15px" }}>
                {addresses.map((address) => (
                  <div
                    key={address._id}
                    className="address-button"
                    onClick={() => handleAddressRowClick(address.addressName)}
                  >
                    <div className="address-text-container">
                      <div className="address-text">
                        {truncateText(address.addressName, 40)}
                      </div>
                    </div>
                    <div className="info-container">
                      <img
                        src={containerIcon}
                        alt="Container Icon"
                        style={{
                          height: 10,
                          verticalAlign: "middle",
                          marginRight: 3,
                        }}
                      />
                      <div className="container-count">{address.count}</div>
                    </div>
                  </div>
                ))}
              </div>
            </article>
          )}
        </section>
      </div>
    </section>
  );
};
