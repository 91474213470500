import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import AppHeaderBar from "./AppHeaderBar";
import AppNavBar from "./AppNavBar";
import {
  formattedDate,
  formattedTime,
} from "../components/formatted/FormattedDateAndTime";
import { useTranslation } from "react-i18next";
import { AppImageTreatment } from "./AppImageTreatment";
import { Input } from "reactstrap";
import {
  getAzureBlob,
  getContainerClientRegisteredInfo,
  saveContainerWeight,
} from "../services/container";
import { getCameraId } from "../shared/utils/utils";
import { LoadingScreen } from "../components/loader/LoadingScreen";

const AppNotificationsDetails = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const azureUrl = process.env.REACT_APP_AZURE_URL;
  const storedUser = localStorage.getItem("user");
  const { token, ...user } = storedUser ? JSON.parse(storedUser) : {};
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState({});
  const [objectRemoved, setObjectRemoved] = useState(true);
  const [materialToDisplay, setMaterialToDisplay] = useState([]);
  const [reason, setReason] = useState(null);
  const [removalTime, setRemovalTime] = useState(
    formattedTime(new Date().toISOString()),
  );
  const [specificReason, setSpecificReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const {
    description,
    date,
    imageUrl,
    xBox,
    yBox,
    wBox,
    hBox,
    detectedObjects,
    containerId,
  } = location.state || {};

  const reasonList = [
    { value: "bulky", label: "Trop volumineux" },
    { value: "covered", label: "Recouvert par une nouvelle couche de déchets" },
    { value: "soiled", label: "Objet souillé" },
    { value: "other", label: "Autre" },
  ];

  useEffect(
    () => {
      let text = "";
      if (materialToDisplay.length > 0) {
        const [, material, number] =
          materialToDisplay[0].nameAndCount.match(/(.+)\s\((\d+)\)/) || [];
        if (objectRemoved) {
          text = `${parseInt(number) > 1 ? "Les " + number + " erreurs de tri" : "L'erreur de tri"} ${material} a été retiré vers ${removalTime}`;
          if (removalTime) {
            setIsReady(true);
          } else {
            setIsReady(false);
          }
        } else {
          if (reason.value === "other") {
            text = `${parseInt(number) > 1 ? "Les " + number + " erreurs de tri" : "L'erreur de tri"} ${material} n'a pas été retiré car ${specificReason.toLowerCase()}`;
            if (specificReason !== "") {
              setIsReady(true);
            } else {
              setIsReady(false);
            }
          } else {
            text = `${parseInt(number) > 1 ? "Les " + number + " erreurs de tri" : "L'erreur de tri"} ${material} n'a pas été retiré car ${reason?.label?.toLowerCase()}`;
            if (reason) {
              setIsReady(true);
            } else {
              setIsReady(false);
            }
          }
        }
      } else {
        setIsReady(false);
      }
      setComment({
        time: new Date().toISOString(),
        author: user,
        text: text,
      });
    },
    // eslint-disable-next-line
    [objectRemoved, materialToDisplay, removalTime, reason, specificReason],
  );

  useEffect(() => {
    const fetchComments = async (id) => {
      setLoading(true);
      await getContainerClientRegisteredInfo(
        {
          start: new Date(new Date(date).getTime() - 1).toISOString(),
          stop: new Date(new Date(date).getTime() + 1).toISOString(),
        },
        id,
      ).then((response) => {
        if (Object.keys(response?.data).length > 0) {
          setComments(
            response?.data?.comments
              ? JSON.parse(response?.data?.comments)
              : [],
          );
        }
      });
      setLoading(false);
    };
    fetchComments(containerId);
  }, [containerId, date]);

  const handleSubmit = async () => {
    setLoading(true);
    let status;
    let commentsList = [];

    if (comment.text !== "") {
      const filteredComments = comments.filter(
        (item) => item.time !== comment.time,
      );

      commentsList = [...filteredComments, comment];

      setComment({
        time: new Date().toISOString(),
        author: user,
        text: "",
      });
    }

    setComments(commentsList);
    const cameraId = getCameraId(imageUrl);
    const blobName = `${
      "camera-ak_" + cameraId
    }/output-client/${date.replace(/:/g, "_").slice(0, -1)}-${
      "camera-ak_" + cameraId
    }-${containerId}-client.json`;
    const blobUrl = azureUrl + blobName;

    const azureResponse = await getAzureBlob({ blobUrl: blobUrl });

    if (azureResponse?.response) {
      status = azureResponse.response.status;
    } else if (azureResponse.status) {
      status = azureResponse.status;
    }

    if (status === 404) {
      await saveContainerWeight({
        id: containerId,
        time: date,
        modified_time: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        blobName: blobName,
        comments: JSON.stringify(commentsList),
      });
    } else {
      let data = azureResponse.data;
      data = {
        ...data,
        modified_time: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        blobName: blobName,
        comments: JSON.stringify(commentsList),
      };
      await saveContainerWeight(data);
    }
    resetData();
    setLoading(false);
  };

  const resetData = () => {
    setObjectRemoved(true);
    setMaterialToDisplay([]);
    setReason(null);
    setRemovalTime(formattedTime(new Date().toISOString()));
    setSpecificReason("");
    setIsReady(false);
  };

  if (!location.state) {
    return <div>No notification data available.</div>;
  }

  return (
    <div>
      <AppHeaderBar title="Notification Details" />
      <div className="mobileapp-container">
        <p>Description: {description}</p>
        <span className="notification-date">
          {" "}
          {formattedDate(date, i18n.language)} - {formattedTime(date)}
        </span>
        <AppImageTreatment
          imageUrl={imageUrl}
          xBox={xBox}
          yBox={yBox}
          wBox={wBox}
          hBox={hBox}
          detectedObjects={detectedObjects}
          materialToDisplay={materialToDisplay}
          setMaterialToDisplay={setMaterialToDisplay}
        />
        <div className="flex flex-col w-full p-3 mb-12">
          {materialToDisplay.length > 0 && (
            <>
              <div className="flex flex-col gap-2 border-t-1 border-b-1 border-grey-300">
                <span className="text-xs mt-2">L'objet a été retiré ?</span>
                <div className="flex flex-row">
                  <div className="flex flex-row">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      style={{ marginLeft: -22 }}
                      checked={objectRemoved}
                      onChange={() => {
                        setObjectRemoved(true);
                        setReason(null);
                        setSpecificReason("");
                      }}
                    />
                    <span className="text-xs" style={{ marginLeft: -18 }}>
                      Oui
                    </span>
                  </div>
                  <div
                    className={`flex flex-row ml-4 w-4/5 ${objectRemoved ? "visible" : "invisible"}`}
                  >
                    <span className="text-xs w-2/5">Heure de retrait :</span>
                    <Input
                      className="h-8 w-3/5"
                      type="time"
                      placeholder={t("Enter a value")}
                      dictcategory="opening"
                      onChange={(e) => setRemovalTime(e.target.value)}
                      name="1"
                      indexarray="0"
                      indexopening="0"
                      style={{ marginTop: -8 }}
                      value={removalTime}
                    />
                  </div>
                </div>
                <div className="flex flex-row mb-2">
                  <div className="flex flex-row">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      style={{ marginLeft: -22 }}
                      checked={!objectRemoved}
                      onChange={() => {
                        setObjectRemoved(false);
                        setReason({ value: "bulky", label: "Trop volumineux" });
                      }}
                    />
                    <span className="text-xs" style={{ marginLeft: -18 }}>
                      Non
                    </span>
                  </div>
                  {!objectRemoved && (
                    <div className="flex flex-col gap-2">
                      <span className="text-xs" style={{ marginLeft: 20 }}>
                        Pourquoi?
                      </span>
                      {reasonList.map((item) => (
                        <div className="flex flex-col">
                          <div className="flex flex-row">
                            <input
                              type="checkbox"
                              className="w-4 h-4"
                              checked={reason.value === item.value}
                              onChange={() => setReason(item)}
                            />
                            <span
                              className="text-xs"
                              style={{ marginLeft: -18 }}
                            >
                              {item.label}
                            </span>
                            {reason.value === "other" &&
                              item.value === "other" && (
                                <Input
                                  className="h-6 w-4/5 ml-2 text-xs"
                                  type="text"
                                  placeholder={"Indiquez la raison"}
                                  dictcategory="opening"
                                  onChange={(e) =>
                                    setSpecificReason(e.target.value)
                                  }
                                  name="1"
                                  indexarray="0"
                                  indexopening="0"
                                  style={{ marginTop: -4 }}
                                />
                              )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-end w-full mt-2">
                <button
                  type="button"
                  class="p-1 w-20 text-sm font-medium text-white rounded-lg"
                  style={{ backgroundColor: isReady ? "#00A7A6" : "#D0D0D0" }}
                  onClick={() => {
                    if (isReady) {
                      handleSubmit();
                    }
                  }}
                >
                  {t("Validate")}
                </button>
              </div>
            </>
          )}
          {comments.length > 0 &&
            comments.reverse().map((item, index) => (
              <div className="flex flex-row gap-2 mt-2.5">
                <div className="flex items-center justify-center text-center rounded-full bg-[#00A7A6] h-7 w-7 p-1">
                  <i className="fas fa-user text-white text-xs"></i>
                </div>
                <div
                  className={`flex flex-col bg-white rounded-md px-3 py-2 w-full ${comment.time === item.time ? "border-2 border-[#00A7A6]" : ""}`}
                >
                  <div className="flex flex-row w-full">
                    <div className="text-xs flex gap-2 w-full">
                      <span className="text-[#00A6A7]">
                        {item?.author.firstName} {item?.author.lastName}
                      </span>
                      <span className="text-gray-500">
                        {formattedDate(item.time, i18n.language)} -{" "}
                        {formattedTime(item.time)}
                      </span>
                    </div>
                  </div>
                  <p className="text-gray-500 text-xs mt-2">{item.text}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
      {loading && <LoadingScreen />}
      <AppNavBar />
    </div>
  );
};

export default AppNotificationsDetails;
