import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppHeaderBar from "./AppHeaderBar";
import AppNavBar from "./AppNavBar";
import { FaExclamationTriangle } from "react-icons/fa";
import { checkIfMobile } from "../components/mobileHelpers";
import {
  formattedDate,
  formattedTime,
} from "../components/formatted/FormattedDateAndTime";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const NotificationRow = ({ notification }) => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const [notificationData, setNotificationData] = useState(notification);

  const styles = {
    row: {
      display: "flex",
      cursor: "pointer",
      padding: "10px",
      borderBottom: "1px solid #ddd",
    },
    icon: {
      marginRight: "10px",
    },
    content: {
      flex: 1,
    },
    date: {
      fontSize: "12px",
      color: "#888",
    },
  };

  useEffect(
    () => {
      const checkAnomalies = async () => {
        let countAnomalies = 0;
        let anomalyDetected = [];

        if (
          notification.detectedObjects &&
          notification.detectedObjects.length > 0
        ) {
          for (const item of notification.detectedObjects) {
            if (!item.isAuthorizedMaterial && item.isNewObjectIOU) {
              countAnomalies += 1;
              anomalyDetected.push(item);
            }
          }
        }

        if (notification.type === "sorting_error") {
          const newNotification = {
            ...notificationData,
            description: `${countAnomalies} erreurs de tri`,
            anomalyDetected: anomalyDetected,
          };
          setNotificationData(newNotification);
        }
      };

      checkAnomalies();
    },
    // eslint-disable-next-line
    [JSON.stringify(notification.detectedObjects)],
  );

  const handleClick = () => {
    history.push(`/app/notificationsdetails`, {
      id: notificationData.id,
      title: notificationData.title,
      description: notificationData.description,
      date: notificationData.date,
      imageUrl: notificationData.imageUrl,
      xBox: notificationData.xBox,
      yBox: notificationData.yBox,
      wBox: notificationData.wBox,
      hBox: notificationData.hBox,
      detectedObjects: notificationData.anomalyDetected,
      containerId: notificationData.containerId,
    });
  };

  return (
    <div className="notification-row" onClick={handleClick} style={styles.row}>
      <div className="notification-icon">
        <FaExclamationTriangle />
      </div>
      <div className="notification-content">
        <span className="notification-date">
          {" "}
          {formattedDate(notificationData.date, i18n.language)} -{" "}
          {formattedTime(notificationData.date)}
        </span>
        <div className="notification-details">
          <h3>{notificationData.description}</h3>
          <p>
            {notificationData.wasteName} - {notificationData.addressName}
          </p>
        </div>
      </div>
    </div>
  );
};

const AppNotifications = () => {
  const [isMobile, setIsMobile] = useState(false);
  const notifications = [...useSelector((state) => state.notification.data)];

  useEffect(() => {
    setIsMobile(checkIfMobile());

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  if (!isMobile) {
    return (
      <div className="mobileapp-container">
        <h1>Cette page est optimisée pour les appareils mobiles</h1>
        <p>
          Veuillez accéder à cette page depuis un appareil mobile pour une
          meilleure expérience.
        </p>
      </div>
    );
  }

  return (
    <div>
      <AppHeaderBar title="Notifications" />
      <div className="mobileapp-container">
        <div className="notifications-list mb-16">
          {notifications.length > 0 &&
            notifications
              .reverse()
              .map((notification) => (
                <NotificationRow
                  key={notification.id}
                  notification={notification}
                />
              ))}
        </div>
      </div>
      <AppNavBar />
    </div>
  );
};

export default AppNotifications;
