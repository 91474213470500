import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  formattedDate,
  formattedTime,
} from "../formatted/FormattedDateAndTime";
import useWindowDimensions from "../formatted/UseWindowsDimensions";
import { CropImage } from "../utils/ImageTreatment";
import axios from "axios";
import Cookies from "js-cookie";
import Loading from "../utils/Loading";
import {
  formattedColorMaterial,
  formattedMaterial,
} from "../formatted/FormattedText";
import { ImageCardDropdownMaterial } from "./ImageCardDropdownMaterial";
import { FaLock } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export const ImageCardComponent = ({
  container,
  displayLegend,
  expendImage,
  setExpendImage,
  history,
  historyId,
  setHistoryId,
  isCharacterization,
  displayAnomalyLegend,
  isActivity,
  containerSortingError,
  containers,
  superContainer,
  proportion,
  displayAllCountours = false,
}) => {
  const { t, i18n } = useTranslation();
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");
  const materialSelectRef = useRef();

  const isAkanthasAccount = (Cookies.get("userEmail") || "").includes(
    "@akanthas.com",
  );

  const { widthWindow, heightWindow } = useWindowDimensions();
  const [temporalData, setTemporalData] = useState([]);
  const [isLoadingTemporalData, setIsLoadingTemporalData] = useState(true);
  const [isLandscape, setIsLandscape] = useState(false);
  const [materialsToDisplay, setMaterialsToDisplay] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [displayText, setDisplayText] = useState(false);
  const [helpReduce, setHelpReduce] = useState(false);
  const [anomalyList, setAnomalyList] = useState([]);
  const [anomaliesObjects, setAnomaliesObjects] = useState([]);

  const handleAnomalyList = useCallback(() => {
    if (temporalData.typeAnomaly) {
      const newAnomalyList = Object.keys(temporalData.typeAnomaly).map(
        (type, index) => ({
          name: `${formattedMaterial(type, i18n.language)}`,
          value: type,
          nameAndCount: `${formattedMaterial(type, i18n.language)} (${temporalData.typeAnomaly[type].count})`,
          color: formattedColorMaterial(type),
        }),
      );
      setAnomalyList(newAnomalyList);
    }
  }, [temporalData.typeAnomaly, i18n.language]);

  const fetchTemporalData = useCallback(
    (id) => {
      setIsLoadingTemporalData(true);

      if (materialSelectRef.current !== undefined) {
        materialSelectRef.current.resetSelectedValues();
        setMaterialsToDisplay([]);
      }

      axios
        .all([
          axios.get(`${API}temporalData/getOne/${id}`, {
            headers: { authorization: `Bearer ${token}` },
            params: {
              time: history[historyId]._time,
            },
          }),
        ])
        .then(
          axios.spread((responseTemporalData) => {
            if (responseTemporalData.status === 200) {
              const materialsTemporalData = [];
              setAnomaliesObjects(
                responseTemporalData.data.temporalData[0].anomaliesObjects,
              );
              if (superContainer) {
                let newTemporalData = {};
                if (
                  responseTemporalData.data.temporalData &&
                  responseTemporalData.data.temporalData.length > 0
                ) {
                  newTemporalData = responseTemporalData.data.temporalData[0];
                  setIsLandscape(
                    responseTemporalData.data.temporalData[0].w_box >
                      responseTemporalData.data.temporalData[0].h_box
                      ? true
                      : false,
                  );
                  responseTemporalData.data.temporalData[0].anomaliesAnnotation.forEach(
                    (annotation) => {
                      const annotationToLowerCase =
                        annotation.cls.toLowerCase();
                      const index = materialsTemporalData.findIndex(
                        (material) =>
                          material.name ===
                          formattedMaterial(
                            annotationToLowerCase,
                            i18n.language,
                          ),
                      );

                      if (index === -1) {
                        materialsTemporalData.push({
                          name: formattedMaterial(
                            annotationToLowerCase,
                            i18n.language,
                          ),
                          value: annotationToLowerCase,
                          color: formattedColorMaterial(annotationToLowerCase),
                          count: 1,
                          nameAndCount: `${formattedMaterial(annotationToLowerCase, i18n.language)} (${1})`,
                        });
                      } else {
                        materialsTemporalData[index].count += 1;
                        materialsTemporalData[index].nameAndCount =
                          `${formattedMaterial(annotationToLowerCase, i18n.language)} (${materialsTemporalData[index].count})`;
                      }
                    },
                  );
                }

                setTemporalData((prevState) => {
                  return { ...prevState, [id]: newTemporalData };
                });
                setMaterialList((prevState) => {
                  if (prevState.length > 0) {
                    const mergedData = prevState.map((prevItem) => {
                      const matchingItemIndex = materialsTemporalData.findIndex(
                        (newItem) => newItem.name === prevItem.name,
                      );
                      if (matchingItemIndex !== -1) {
                        const count =
                          prevItem.count +
                          materialsTemporalData[matchingItemIndex].count;
                        return {
                          ...prevItem,
                          count: count,
                          nameAndCount: `${formattedMaterial(materialsTemporalData[matchingItemIndex].name, i18n.language)} (${count})`,
                        };
                      } else {
                        return prevItem;
                      }
                    });
                    materialsTemporalData.forEach((newItem) => {
                      const matchingPrevItemIndex = mergedData.findIndex(
                        (prevItem) => prevItem.name === newItem.name,
                      );
                      if (matchingPrevItemIndex === -1) {
                        mergedData.push(newItem);
                      }
                    });
                    return mergedData;
                  } else {
                    return materialsTemporalData;
                  }
                });
              } else {
                responseTemporalData.data.temporalData[0].anomaliesAnnotation.forEach(
                  (annotation) => {
                    const annotationToLowerCase = annotation.cls.toLowerCase();
                    const index = materialsTemporalData.findIndex(
                      (material) =>
                        material.name ===
                        formattedMaterial(annotationToLowerCase, i18n.language),
                    );

                    if (index === -1) {
                      materialsTemporalData.push({
                        name: formattedMaterial(
                          annotationToLowerCase,
                          i18n.language,
                        ),
                        value: annotationToLowerCase,
                        color: formattedColorMaterial(annotationToLowerCase),
                        count: 1,
                        nameAndCount: `${formattedMaterial(annotationToLowerCase, i18n.language)} (${1})`,
                      });
                    } else {
                      materialsTemporalData[index].count += 1;
                      materialsTemporalData[index].nameAndCount =
                        `${formattedMaterial(annotationToLowerCase, i18n.language)} (${materialsTemporalData[index].count})`;
                    }
                  },
                );

                setTemporalData(
                  responseTemporalData.data.temporalData.length > 0 &&
                    responseTemporalData.data.temporalData[0],
                );
                setIsLandscape(
                  responseTemporalData.data.temporalData[0].w_box >
                    responseTemporalData.data.temporalData[0].h_box
                    ? true
                    : false,
                );
                setMaterialList(materialsTemporalData);
              }
            }
          }),
        );
    },
    // eslint-disable-next-line
    [API, token, history, historyId],
  );

  useEffect(
    () => {
      setTemporalData([]);
      setMaterialList([]);
      if (superContainer) {
        containers.forEach((item) => {
          fetchTemporalData(item._id);
        });
      } else {
        fetchTemporalData(container._id);
      }
    },
    // eslint-disable-next-line
    [fetchTemporalData],
  );

  useEffect(() => {
    handleAnomalyList();
  }, [handleAnomalyList]);

  useEffect(() => {
    if (Object.keys(temporalData).length > 1) {
      setIsLoadingTemporalData(false);
    }
  }, [temporalData]);

  const getImageWidth = () => {
    if (expendImage) {
      if (superContainer) {
        return widthWindow / 2.5;
      } else {
        return widthWindow;
      }
    }

    if (isLandscape) {
      if (isActivity) {
        return widthWindow / 3;
      } else if (containerSortingError) {
        return widthWindow / 3.2;
      } else if (superContainer) {
        return widthWindow / 2.5;
      } else {
        return widthWindow / 2;
      }
    } else {
      return ((widthWindow / 2) * 66) / 100;
    }
  };

  const getImageHeight = () => {
    if (expendImage) {
      return heightWindow - (heightWindow * 10) / 100;
    }
    return widthWindow / 3;
  };

  const tooglePicture = () => {
    setExpendImage(!expendImage);
  };

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX + 50, y: event.screenY - 350 });
    setDisplayText(true);
  };

  const imageWidth = getImageWidth();
  const imageHeight = getImageHeight();
  const numberOfElements = history.length;

  return (
    <section className="relative flex flex-col">
      {isCharacterization && (
        <section
          className={`flex w-full h-24 ${expendImage ? "absolute z-10 left-1 justify-between" : superContainer ? "justify-center" : "justify-end"} `}
        >
          <div className={`${expendImage ? "w-1/4" : "w-1/2"} my-auto mr-2`}>
            <ImageCardDropdownMaterial
              materialList={materialList}
              materialSelectRef={materialSelectRef}
              materialsToDisplay={materialsToDisplay}
              setMaterialsToDisplay={setMaterialsToDisplay}
            />
          </div>

          {expendImage && (
            <div className="relative">
              <svg
                className="cursor-pointer"
                onClick={() => {
                  tooglePicture();
                  setHelpReduce(false);
                }}
                onMouseMove={() => setHelpReduce(true)}
                onMouseLeave={() => setHelpReduce(false)}
                width="50px"
                height="50px"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 14.5L10.5 14.5V19M19 10.5H14.5L14.5 6"
                  stroke="#6C757D"
                  strokeWidth="1.2"
                />
                <path d="M10.5 14.5L6 19" stroke="#6C757D" strokeWidth="1.2" />
                <path d="M14.5 10.5L19 6" stroke="#6C757D" strokeWidth="1.2" />
              </svg>
              <div
                className={`absolute -top-4 -left-16 bg-yellow text-white text-xs px-3 py-1 rounded-md ${helpReduce ? "opacity-100" : "opacity-0"}`}
              >
                {t("Reduce")}
              </div>
            </div>
          )}
        </section>
      )}

      {/* Navigation Button */}
      {history[historyId] !== undefined && !expendImage && (
        <div className="visual-card visual-card-navigation flex w-2/3 mx-auto">
          <button
            onClick={(event) => {
              event.preventDefault();
              setHistoryId(Math.max(0, historyId - 1));
            }}
            className={`mt-2 mx-4 ${historyId === 0 ? "previous-inactive round" : "previous-active round"}`}
          >
            <i className="fas fa-angle-left"></i>
          </button>
          <p className="data-bold">
            {formattedDate(history[historyId]._time, i18n.language)} -{" "}
            {formattedTime(history[historyId]._time)}
          </p>
          <button
            onClick={(event) => {
              event.preventDefault();
              setHistoryId(Math.min(historyId + 1, numberOfElements - 1));
            }}
            className={`mt-2 mx-4 ${historyId === numberOfElements - 1 ? "next-inactive round" : "next-active round"}`}
          >
            <i className="fas fa-angle-right"></i>
          </button>
        </div>
      )}

      {/* Image */}
      {isLoadingTemporalData ? (
        <Loading height={`${imageHeight}px`} />
      ) : (
        <div
          className={`relative transition-opacity duration-300 ease-out  ${isCharacterization && !expendImage && "cursor-pointer  hover:opacity-80"} ${expendImage && "transition-transform"}`}
          onMouseMove={handleMouseMove}
          onMouseLeave={() => setDisplayText(false)}
          style={{
            transform: expendImage
              ? `translate(${0}px, ${5}px ) ${superContainer ? "" : `scale(${widthWindow / imageWidth}, ${widthWindow / imageWidth})`}`
              : "",
            transformOrigin: "top left",
          }}
        >
          {superContainer ? (
            <div className="flex flex-row w-full">
              <div className="w-1/2">
                <CropImage
                  container={containers[0]}
                  data={temporalData[containers[0]._id]}
                  displayLegend={displayLegend}
                  imgDivWidth={imageWidth}
                  imgDivHeight={imageHeight}
                  isTooltip={false}
                  isMap={false}
                  isCharacterization={isCharacterization}
                  materialsDropdownToDisplay={materialsToDisplay}
                  showCheckbox={false}
                  displayAnomalyLegend={displayAnomalyLegend}
                  anomalyList={anomalyList}
                  isActivity={isActivity}
                  history={history}
                  containerSortingError={containerSortingError}
                  expendImage={expendImage}
                  tooglePicture={tooglePicture}
                  {...(!displayAllCountours && { anomaliesObjects })} //  Spread anomaliesObjects if displayAllCountours is true
                />
                <p style={{ marginLeft: 10 }}>
                  {isAkanthasAccount ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <FaLock style={{ marginRight: "10px" }} />
                      <span>
                        {" Left proportion: " +
                          (100 * proportion[0]).toFixed(1) +
                          "%"}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="w-1/2">
                <CropImage
                  container={containers[1]}
                  data={temporalData[containers[1]._id]}
                  displayLegend={displayLegend}
                  imgDivWidth={imageWidth}
                  imgDivHeight={imageHeight}
                  isTooltip={false}
                  isMap={false}
                  isCharacterization={isCharacterization}
                  materialsDropdownToDisplay={materialsToDisplay}
                  showCheckbox={false}
                  displayAnomalyLegend={displayAnomalyLegend}
                  anomalyList={anomalyList}
                  isActivity={isActivity}
                  history={history}
                  containerSortingError={containerSortingError}
                  expendImage={expendImage}
                  tooglePicture={tooglePicture}
                  {...(!displayAllCountours && { anomaliesObjects })} //  Spread anomaliesObjects if displayAllCountours is true
                />
                <p style={{ marginLeft: 10 }}>
                  {isAkanthasAccount ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <FaLock style={{ marginRight: "10px" }} />
                      <span>
                        {" Right proportion: " +
                          (100 * proportion[1]).toFixed(1) +
                          "%"}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
          ) : (
            <CropImage
              container={container}
              data={temporalData}
              displayLegend={displayLegend}
              imgDivWidth={imageWidth}
              imgDivHeight={imageHeight}
              isTooltip={false}
              isMap={false}
              isCharacterization={isCharacterization}
              materialsDropdownToDisplay={materialsToDisplay}
              showCheckbox={false}
              displayAnomalyLegend={displayAnomalyLegend}
              anomalyList={anomalyList}
              isActivity={isActivity}
              history={history}
              containerSortingError={containerSortingError}
              expendImage={expendImage}
              tooglePicture={tooglePicture}
              {...(!displayAllCountours && { anomaliesObjects })}
            />
          )}
          <div
            className={`absolute bg-yellow text-white text-xs p-1 rounded ${displayText && isCharacterization && !expendImage ? "opacity-100" : "opacity-0"}`}
            style={{
              left: `${mousePosition.x}px`,
              top: `${mousePosition.y}px`,
            }}
          >
            {t("Expand")}
          </div>
        </div>
      )}
    </section>
  );
};
