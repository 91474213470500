import axiosInstance from "./axiosInstance";

export const getSuperContainerList = () => {
  return axiosInstance.get(`superContainers/getSuperContainerList`);
};

export const getContainersList = (params) => {
  return axiosInstance.get(`containers`, { params: params });
};

export const getContainersOptions = () => {
  return axiosInstance.get(`containers/options`);
};

export const getHistoryCycleContainer = (params, id, signal) => {
  return axiosInstance.get(`container/historyCycleContainer/${id}`, {
    params: params,
    signal: signal,
  });
};

export const getHistoryFillingSpeed = (id) => {
  return axiosInstance.get(`container/historyFillingSpeed/${id}`);
};

export const getHistoryFilling = (params, id) => {
  return axiosInstance.get(`container/historyFilling/${id}`, {
    params: params,
  });
};

export const getHistoryMaterial = (params, id) => {
  return axiosInstance.get(`container/historyMaterial/${id}`, {
    params: params,
  });
};

export const getHistoryActivity = (params) => {
  return axiosInstance.get(`history/activity`, { params: params });
};

export const getHistoryCollection = (params, signal) => {
  return axiosInstance.get(`history/collection`, {
    params: params,
    signal: signal,
  });
};

export const countAnomaliesData = (params) => {
  return axiosInstance.get(`anomalies/count`, { params: params });
};

export const saveContainerWeight = (data) => {
  return axiosInstance.post(`container/weight`, data);
};

export const getContainerClientRegisteredInfo = (params, id, signal) => {
  return axiosInstance.get(`container/clientRegisteredInfo/${id}`, {
    params: params,
    signal: signal, // Pass the abort signal here
  });
};

export const getContainerClient = (id) => {
  return axiosInstance.get(`container/clients/${id}`);
};

export const countContainers = (params) => {
  return axiosInstance.get(`containers/count`, { params: params });
};

export const getListRotationPeriods = (params, id) => {
  return axiosInstance.get(`period/listRotationPeriods/${id}`, {
    params: params,
  });
};

export const getTemporalData = (params, id, signal) => {
  return axiosInstance.get(`temporalData/getOne/${id}`, {
    params: params,
    signal: signal, // Pass the abort signal here
  });
};

export const getKeywordFilterOptions = (params) => {
  return axiosInstance.get(`getKeywordFilterOptions`, { params: params });
};

export const countCollectionData = (params) => {
  return axiosInstance.get(`collection/count`, { params: params });
};

export const getCollectionWasteGenerated = (params) => {
  return axiosInstance.get(`collection/wasteGenerated`, { params: params });
};

export const getStatisticsCollection = (params) => {
  return axiosInstance.get(`getStatisticsCollection`, { params: params });
};

export const getContainerMaterialKey = (params) => {
  return axiosInstance.get(`getContainerMaterialKey`, { params: params });
};

export const getStatisticsQuality = (params) => {
  return axiosInstance.get(`getStatisticsQuality`, { params: params });
};

export const updateOutputSurface = (params) => {
  return axiosInstance.post(`container/outputSurface`, params);
};

export const getAzureBlob = (params) => {
  return axiosInstance.get(`container/getAzureBlob`, { params: params });
};

export const getAzureBlobFile = (params) => {
  return axiosInstance.get(`container/getAzureBlobFile`, {
    responseType: "blob",
    params: params,
  });
};

export const azureUploadFile = (params) => {
  return axiosInstance.post(`container/azureUploadFile`, params);
};

export const azureDeleteBlob = (params) => {
  return axiosInstance.post(`container/azureDeleteBlob`, params);
};

export const totalVolumeCharacterization = (params) => {
  return axiosInstance.get(`totalVolumeCharacterization`, { params: params });
};

export const getContainerFillingAlert = (id) => {
  return axiosInstance.get(`container/fillingAlert/${id}`);
};

export const getCo2Generated = (params) => {
  return axiosInstance.get(`collection/co2Generated`, { params: params });
};
