import React, { useEffect, useState } from "react";
import axios from "axios";
import AppHeaderBar from "./AppHeaderBar";
import AppInstallationRow from "./AppInstallationRow";
import AppNavBar from "./AppNavBar";
import { checkIfMobile } from "../components/mobileHelpers";
import Cookies from "js-cookie";
import { FaMapMarkerAlt } from "react-icons/fa";

const AppInstallation = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(checkIfMobile());

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(`${API}cameras`, {
            headers: { authorization: `Bearer ${token}` },
            params: {
              all: false,
              keywordFilter: "",
              sortByField: "name",
              orderSortByField: 1,
              batteryLevelFilter: [0, 100],
              startDateFilter: null,
              stopDateFilter: null,
              page: 1,
              limit: 50,
            },
          }),
        ])
        .then(
          axios.spread((responseCameras) => {
            if (responseCameras.status === 200) {
              setTableData(
                responseCameras.data.dataCamerasList.filter(
                  (item) => !item._id.endsWith("_r"),
                ),
              );
            }
          }),
        );
    };
    fetchData();
  });

  const sortedData = tableData
    .filter((item) => !item._id.endsWith("_r")) // Filtrer les items avec un _id terminant par "_r"
    .sort((a, b) => a.address[0].name.localeCompare(b.address[0].name)); // Trier par adresse

  // Grouper par adresse et afficher les groupes avec un titre d'adresse
  const groupedByAddress = sortedData.reduce((acc, item) => {
    const address = item.address[0].name;

    if (!acc[address]) {
      acc[address] = [];
    }

    acc[address].push(item);
    return acc;
  }, {});

  if (!isMobile) {
    return (
      <div className="mobileapp-container">
        <h1>Cette page est optimisée pour les appareils mobiles</h1>
        <p>
          Veuillez accéder à cette page depuis un appareil mobile pour une
          meilleure expérience.
        </p>
      </div>
    );
  }

  return (
    <div>
      <AppHeaderBar title="Installation" />
      <div className="mobileapp-container">
        <div className="installation-table">
          {Object.keys(groupedByAddress).map((address) => (
            <div key={address} style={{ marginBottom: "50px" }}>
              <h2
                className="address-header"
                style={{ display: "flex", alignItems: "center" }}
              >
                <FaMapMarkerAlt
                  style={{ marginRight: "8px", verticalAlign: "middle" }}
                />
                {address}
              </h2>

              {groupedByAddress[address].map((item) => (
                <AppInstallationRow item={item} />
              ))}
            </div>
          ))}
        </div>
      </div>
      <AppNavBar />
    </div>
  );
};

export default AppInstallation;
