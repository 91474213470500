import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import DeleteModal from "../../components/modals/DeleteModal";
import { formattedContainerId } from "../../components/formatted/FormattedText";
import { handleOpenModalClick } from "../../components/modals/EditableTable";
import ContainerVisual from "../../components/formatted/LoadImages";
import useWindowDimensions from "../../components/formatted/UseWindowsDimensions";
import { CropImage } from "../../components/utils/ImageTreatment";
import ContainerThumbnailView from "./ContainerThumbnailView";
import ContainerTableView from "./ContainerTableView";
import {
  checkContainerContractExist,
  checkPlanningIsActive,
  handleSelectOne,
} from "./utilsTable";
import { getMostRecentHistory } from "../../shared/utils/utils";
import { useDispatch } from "react-redux";
import { toggleContainer } from "../../redux/containerSlice";
import {
  getContainerFillingAlert,
  getHistoryCycleContainer,
  getHistoryFillingSpeed,
} from "../../services/container";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ContainerRow = ({
  containersSelected,
  setContainersSelected,
  setConfirmationCollectModal,
  data,
  displayToBlock,
  formData,
  setFormData,
  setModalEdit,
  fullData,
  setFullData,
  tableData,
  setTableData,
  settings,
  screenWidth,
  noActivePackmat,
  toggleSortingErrorOverlay,
  setDataForSortingErrorOverlay,
  toggleCompactOverlay,
  setDataForCompactOverlay,
  toggleImageZoomOverlay,
  toggleMapOverlay,
  setDataForMapOverlay,
  containerActive,
  setContainerActive,
  superContainer,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [containers, setContainers] = useState([]);
  const [fillingAlert, setFillingAlert] = useState({});
  const API = process.env.REACT_APP_API_BACKEND;
  const max_session_size = process.env.REACT_APP_MAX_SESSION_SIZE;
  const token = Cookies.get("userToken");
  const { widthWindow } = useWindowDimensions();
  const dispatch = useDispatch();

  let imageWidth = widthWindow < 1320 ? 220 : displayToBlock ? 240 : 140;
  let imageHeight = widthWindow < 1320 ? 220 : displayToBlock ? 240 : 140;
  let deltaAlert = Math.floor(
    (new Date()?.getTime() - new Date(data._time)?.getTime()) / 86400000,
  );

  const [isLoadingHistoryCycleContainer, setIsLoadingHistoryCycleContainer] =
    useState(true);
  const index = fullData.findIndex((item) => item._id === data._id);
  const [containerData, setContainerData] = useState({});

  const fetchData = useCallback(
    () => {
      axios
        .all([
          getHistoryCycleContainer(
            {
              currentCycle: true,
              getLevelLastRotation: true,
            },
            data._id,
          ),
          getHistoryFillingSpeed(data._id),
          getContainerFillingAlert(data._id),
        ])
        .then(
          axios.spread(
            (
              responseHistoryCurrent,
              responseSpeedFilling,
              responseFillingAlert,
            ) => {
              if (
                responseHistoryCurrent.status === 200 ||
                responseSpeedFilling.status === 200 ||
                responseFillingAlert.status === 200
              ) {
                let dataModal = containerData;
                if (
                  responseSpeedFilling.data.durationFullFillingMs.length > 0
                ) {
                  dataModal = {
                    ...dataModal,
                    durationFullFillingMs:
                      responseSpeedFilling.data.durationFullFillingMs[0]
                        .duration,
                    reliablePrediction:
                      responseSpeedFilling.data.reliablePrediction[0],
                    fromDate: responseSpeedFilling.data.fromDate,
                    meanFillingLevelByOpenedDay:
                      responseSpeedFilling.data.fillingPerOpenedWeekday[0],
                    meanFillingLevelByOpenedHour:
                      responseSpeedFilling.data.fillingPerOpenedHour[0],
                    meanFillingLevelByDay:
                      responseSpeedFilling.data.fillingPerWeekday[0],
                  };
                  setFullData((prevFullData) => {
                    return prevFullData.map((item) => {
                      if (item._id === data._id) {
                        return {
                          ...item,
                          durationFullFillingMs:
                            responseSpeedFilling.data.durationFullFillingMs,
                          reliablePrediction:
                            responseSpeedFilling.data.reliablePrediction,
                          fromDate: new Date(
                            responseSpeedFilling.data.fromDate,
                          ),
                        };
                      }
                      return item;
                    });
                  });
                }

                dataModal = {
                  ...dataModal,
                  historyCurrent: responseHistoryCurrent.data.historyCycle,
                  numberOfStars: responseHistoryCurrent.data.numberOfStars,
                  numberOfAnomaly: responseHistoryCurrent.data.numberOfAnomaly,
                  anomalyDelivery: responseHistoryCurrent.data.anomalyDelivery,
                  numberOfPackmat: responseHistoryCurrent.data.numberOfPackmat,
                  totalVolumeSortingError:
                    responseHistoryCurrent.data.totalVolumeSortingError,
                  totalWasteVolume:
                    responseHistoryCurrent.data.totalWasteVolume,
                  levelLastRotation:
                    responseHistoryCurrent.data.levelLastRotation[0],
                  dateLastRotation:
                    responseHistoryCurrent.data.historyCycle[
                      responseHistoryCurrent.data.historyCycle.length - 1
                    ],
                  lastRotation: responseHistoryCurrent.data.lastRotation[0],
                  lastPackmatEfficiency:
                    responseHistoryCurrent.data.lastPackmatEfficiency[0],
                };
                setContainerData(dataModal);
                dispatch(toggleContainer({ [data._id]: dataModal }));
                setFullData((prevFullData) => {
                  return prevFullData.map((item) => {
                    if (item._id === data._id) {
                      return {
                        ...item,
                        historyCurrent:
                          responseHistoryCurrent.data.historyCycle,
                        numberOfStars:
                          responseHistoryCurrent.data.numberOfStars,
                        numberOfAnomaly:
                          responseHistoryCurrent.data.numberOfAnomaly,
                        anomalyDelivery:
                          responseHistoryCurrent.data.anomalyDelivery,
                        numberOfPackmat:
                          responseHistoryCurrent.data.numberOfPackmat,
                        totalVolumeSortingError:
                          responseHistoryCurrent.data.totalVolumeSortingError,
                        totalWasteVolume:
                          responseHistoryCurrent.data.totalWasteVolume,
                        meanFillingLevelByOpenedDay:
                          responseSpeedFilling.data.fillingPerOpenedWeekday[0],
                        meanFillingLevelByOpenedHour:
                          responseSpeedFilling.data.fillingPerOpenedHour[0],
                        meanFillingLevelByDay:
                          responseSpeedFilling.data.fillingPerWeekday[0],
                        levelLastRotation:
                          responseHistoryCurrent.data.levelLastRotation[0],
                        dateLastRotation:
                          responseHistoryCurrent.data.historyCycle[
                            responseHistoryCurrent.data.historyCycle.length - 1
                          ],
                        lastRotation:
                          responseHistoryCurrent.data.lastRotation[0],
                        lastPackmatEfficiency:
                          responseHistoryCurrent.data.lastPackmatEfficiency[0],
                      };
                    }
                    return item;
                  });
                });
                setIsLoadingHistoryCycleContainer(false);
                setFillingAlert(responseFillingAlert.data);
              }
            },
          ),
        );
    },
    // eslint-disable-next-line
    [API, token, data],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (superContainer) {
      const filtered = fullData.filter((item) =>
        superContainer.containerObjectIdList.includes(item._id),
      );
      const sortedData = filtered.sort((a, b) => {
        if (
          a.image_url.includes("-R.jpeg") &&
          !b.image_url.includes("-R.jpeg")
        ) {
          return 1;
        } else if (
          !a.image_url.includes("-R.jpeg") &&
          b.image_url.includes("-R.jpeg")
        ) {
          return -1;
        } else {
          return 0;
        }
      });
      setContainers(sortedData);
      setIsLoadingHistoryCycleContainer(false);
    }
  }, [superContainer, fullData]);

  const currentDate = new Date();

  const setFillingWeightColor = (dataWeight, dataFullWeight, weightUnit) => {
    if (weightUnit === `t`) {
      dataFullWeight = dataFullWeight * 1000;
    }
    if (isNaN(dataFullWeight) || dataFullWeight === 0) {
      return "text-grey";
    } else if (dataWeight === dataFullWeight) {
      return "text-blue";
    } else if (dataWeight > dataFullWeight) {
      return "text-red";
    } else if (dataWeight < dataFullWeight) {
      return "text-grey";
    } else {
      return "text-grey";
    }
  };

  const weightKG = getMostRecentHistory(fullData[index].historyCurrent)?.mass;

  const weightToTonnes = (weightKg, weightUnit) => {
    if (weightUnit === "t") {
      return weightKg / 1000;
    } else {
      return weightKg;
    }
  };

  const dataIsReady = (id) => {
    const filteredData = fullData.filter((item) => item._id === id);
    if (filteredData.length > 0) {
      if (filteredData[0]?.historyCurrent || filteredData[0].characterization) {
        return true;
      }
    }
    return false;
  };

  const handleOpenNewTabClick = (container) => {
    const _time = new Date().toISOString();
    const filteredData = fullData.filter((item) => item._id === container._id);
    const countContentCategory = fullData.filter(
      (item) =>
        data.contentCategoryJoin.label === item.contentCategoryJoin.label,
    ).length;
    if (superContainer) {
      const containerDetails = {
        _time: _time,
        container: filteredData[0],
        containers: containers,
        superContainer: location.pathname.includes("characterization")
          ? superContainer
          : null,
        countContentCategory: countContentCategory,
        viewType: location.pathname.includes("characterization")
          ? "characterization"
          : "monitoring",
      };
      sessionStorage.setItem(
        "containerDetails",
        JSON.stringify(containerDetails),
      );
      if (location.pathname.includes("characterization")) {
        window.open(`/characterization/${superContainer._id}`, "_blank");
      } else {
        window.open(`/container/${superContainer._id}`, "_blank");
      }
    } else {
      let dataContainer = {
        ...fullData.find((item) => item._id === container._id),
      };
      const historyCurrentSize = new Blob([
        JSON.stringify(dataContainer.historyCurrent),
      ]).size;
      if (historyCurrentSize > max_session_size) {
        delete dataContainer.historyCurrent;
      }
      const containerDetails = {
        _id: container._id,
        _time: _time,
        container: dataContainer,
        containers: tableData,
        countContentCategory: countContentCategory,
        viewType: location.pathname.includes("characterization")
          ? "characterization"
          : "monitoring",
      };
      sessionStorage.setItem(
        "containerDetails",
        JSON.stringify(containerDetails),
      );
      if (location.pathname.includes("characterization")) {
        window.open(`/characterization/${container._id}`, "_blank");
      } else {
        window.open(`/container/${container._id}`, "_blank");
      }
    }
  };

  return (
    <section
      id={`${data._id}`}
      className={`w-full ${displayToBlock && containerActive && containerActive._id === data._id && "border-solid border-cyan border-2"} ${displayToBlock ? "table-block-card" : "table-block"}`}
      style={{
        filter:
          deltaAlert > settings.alertNumberOfDays ? "grayscale(100%)" : "none",
      }}
    >
      <div
        className={` ${!displayToBlock ? "data-image-block" : "data-image-display"}`}
        style={{ width: `${displayToBlock ? 38 : 0}%` }}
      >
        {data.image_url ? (
          <CropImage
            container={data}
            data={data}
            containersSelected={containersSelected}
            setContainersSelected={setContainersSelected}
            imgDivWidth={imageWidth}
            imgDivHeight={imageHeight}
            isTooltip={true}
            isMap={false}
            isCharacterization={false}
            displayLegend={false}
            materialsDropdownToDisplay={[]}
            showCheckbox={true}
            onClickZoomButton={() => {
              setContainerActive(data);
              toggleImageZoomOverlay();
            }}
            displayToBlock={displayToBlock}
            setContainerActive={setContainerActive}
          />
        ) : (
          <ContainerVisual type={data.type} width={imageWidth} />
        )}
      </div>

      <section
        className={`table-line-data`}
        style={{
          width: `${screenWidth > 1280 && !displayToBlock ? "" : "62%"}`,
        }}
      >
        <div className={`flex w-full relative my-1 rounded-md bg-white`}>
          <input
            type="checkbox"
            className={`${displayToBlock ? "hidden" : ""} my-auto w-4 h-4 cursor-pointer`}
            onChange={(event) =>
              handleSelectOne(
                event,
                data,
                setContainersSelected,
                containersSelected,
              )
            }
            checked={data.checked}
          />

          <div
            className={`
              ${displayToBlock && screenWidth < 1280 ? "flex-col" : displayToBlock && "ml-2"} 
              flex
              justify-between 
              py-2
              w-full
              rounded-md
              ${!displayToBlock && "hover:bg-white-transparent" && !displayToBlock && "cursor-pointer"}
            `}
            onClick={() => {
              if (dataIsReady(data._id) && !displayToBlock) {
                if (superContainer) {
                  if (containers.length > 1) {
                    handleOpenNewTabClick(containers[0]);
                  }
                } else {
                  handleOpenNewTabClick(data);
                }
                setContainerActive(data);
              }
            }}
          >
            {displayToBlock ? (
              <ContainerThumbnailView
                data={data}
                weightKG={weightKG}
                numberOfStars={fullData[index].numberOfStars}
                numberOfAnomaly={fullData[index].numberOfAnomaly}
                numberOfPackmat={fullData[index].numberOfPackmat}
                durationFullFillingMs={fullData[index].durationFullFillingMs}
                reliablePrediction={fullData[index].reliablePrediction}
                fromDate={fullData[index].fromDate}
                currentDate={currentDate}
                isLoadingHistoryCycleContainer={isLoadingHistoryCycleContainer}
                toggleSortingErrorOverlay={toggleSortingErrorOverlay}
                setDataForSortingErrorOverlay={setDataForSortingErrorOverlay}
                historyCurrent={fullData[index].historyCurrent}
                toggleCompactOverlay={toggleCompactOverlay}
                setDataForCompactOverlay={setDataForCompactOverlay}
                toggleMapOverlay={toggleMapOverlay}
                setDataForMapOverlay={setDataForMapOverlay}
                setContainerActive={setContainerActive}
                fillingAlert={fillingAlert}
                totalVolumeSortingError={
                  fullData[index].totalVolumeSortingError
                }
                totalWasteVolume={fullData[index].totalWasteVolume}
              />
            ) : (
              <ContainerTableView
                data={data}
                deltaAlert={deltaAlert}
                settings={settings}
                setFillingWeightColor={setFillingWeightColor}
                weightKG={weightKG}
                weightToTonnes={weightToTonnes}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                screenWidth={screenWidth}
                numberOfStars={fullData[index].numberOfStars}
                numberOfAnomaly={fullData[index].numberOfAnomaly}
                numberOfPackmat={fullData[index].numberOfPackmat}
                durationFullFillingMs={fullData[index].durationFullFillingMs}
                reliablePrediction={fullData[index].reliablePrediction}
                fromDate={fullData[index].fromDate}
                currentDate={currentDate}
                isLoadingHistoryCycleContainer={isLoadingHistoryCycleContainer}
                noActivePackmat={noActivePackmat}
                fillingAlert={fillingAlert}
                totalVolumeSortingError={
                  fullData[index].totalVolumeSortingError
                }
                totalWasteVolume={fullData[index].totalWasteVolume}
              />
            )}

            {/* Action */}
            <article
              className={` ${displayToBlock ? "data-action-block mt-3" : "data-table data-action z-9"}`}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <i
                className={` button-action ${!isOpen ? "fas fa-ellipsis-v" : "fas fa-times"}`}
                onClick={() => setIsOpen(!isOpen)}
              ></i>
              <i
                className="button-action fas fa-eye"
                onClick={() => {
                  if (dataIsReady(data._id)) {
                    if (superContainer) {
                      if (containers.length > 1) {
                        handleOpenNewTabClick(containers[0]);
                      }
                    } else {
                      handleOpenNewTabClick(data);
                    }
                    setContainerActive(data);
                  }
                }}
                style={{
                  color: dataIsReady(data._id)
                    ? "var(--akanthas-cyan)"
                    : "#d1d5db",
                }}
              ></i>
              <div
                className={`action-choice ${isOpen ? "display-action" : ""}`}
              >
                {checkContainerContractExist(
                  [data],
                  [{ _id: data._id, _time: data._time }],
                ) === true && (
                  <button
                    className={`action ${!checkPlanningIsActive([data], [{ _id: data._id, _time: data._time }]) ? "disabled" : ""}`}
                    disabled={
                      !checkPlanningIsActive(
                        [data],
                        [{ _id: data._id, _time: data._time }],
                      )
                    }
                    onClick={(event) => {
                      handleSelectOne(
                        event,
                        data,
                        setContainersSelected,
                        containersSelected,
                      );
                      setConfirmationCollectModal(true);
                    }}
                  >
                    {t("Request a rotation")}
                  </button>
                )}
                <button
                  className="action"
                  onClick={() => {
                    handleOpenModalClick(
                      data,
                      formData,
                      setFormData,
                      setModalEdit,
                    );
                    setIsOpen(!isOpen);
                  }}
                >
                  {t("Edit")}
                </button>
                <button
                  className="action"
                  onClick={() => {
                    setModalDelete(true);
                    setIsOpen(!isOpen);
                  }}
                >
                  {t("Delete")}
                </button>
              </div>
            </article>
          </div>
        </div>

        {/* Modal Delete*/}
        {modalDelete && (
          <DeleteModal
            modal={modalDelete}
            setModal={setModalDelete}
            id={data._id}
            message1={
              t("Are you sure you want to delete this container") +
              " " +
              formattedContainerId(data.containerId) +
              data.containerId +
              " ?"
            }
            message2={formattedContainerId(data.containerId) + data.containerId}
            messageValidation={t("The container has been successfully deleted")}
            path={"container/delete/"}
            tableData={tableData}
            setTableData={setTableData}
          />
        )}
      </section>
    </section>
  );
};

export default ContainerRow;
