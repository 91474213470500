import React, { useEffect, useState } from "react";
import AppHeaderBar from "./AppHeaderBar";
import AppNavBar from "./AppNavBar";
import { checkIfMobile } from "../components/mobileHelpers";

const AppHome = () => {
  const [isMobile, setIsMobile] = useState(false);
  const storedUser = localStorage.getItem("user");
  const { token, ...user } = storedUser ? JSON.parse(storedUser) : {};

  useEffect(() => {
    setIsMobile(checkIfMobile());

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  if (!isMobile) {
    return (
      <div className="mobileapp-container">
        <h1>Cette page est optimisée pour les appareils mobiles</h1>
        <p>
          Veuillez accéder à cette page depuis un appareil mobile pour une
          meilleure expérience.
        </p>
      </div>
    );
  }

  return (
    <div>
      <AppHeaderBar title="Akanthas" />
      <div className="mobileapp-container">
        <div className="flex flex-col mt-44 text-center text-gray-600">
          <span className="text-xl">Bienvenue</span>
          <span className="text-xl">
            {user?.firstName} {user?.lastName}
          </span>
        </div>
      </div>
      <AppNavBar />
    </div>
  );
};

export default AppHome;
