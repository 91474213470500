import { useEffect, useState } from "react";
import Loading from "../../../components/utils/Loading";
import { formattedWasteColor } from "../../../components/formatted/FormattedText";
import MultipleLineAndBarChart from "../../../components/charts/MultipleLineAndBarChart";
import { getStatisticsCollection } from "../../../services/container";
import { useTranslation } from "react-i18next";

const StatisticsChartComponent = ({
  periodHistoryStart,
  periodHistoryStop,
  keywordFilter,
  handleValidate,
  setHandleValidate,
  setOpenFilter,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  //Filling chart display
  const [displayFillingByFlux, setDisplayFillingByFlux] = useState(true);
  const [displayFillingByTime, setDisplayFillingByTime] = useState(false);

  //Chart variable construction
  let dataSetsCollectionByFluxChart = [];
  let dataSetsFillingLevelByFluxChart = [];
  let dataSetsFillingLevelByTime = [];
  let labelsByTimeChart = [];
  let labelsByFluxChart = [];

  const fetchData = () => {
    setIsLoading(true);
    setOpenFilter(false);
    getStatisticsCollection({
      periodStart: periodHistoryStart,
      periodStop: periodHistoryStop,
      keywordFilter: JSON.stringify(keywordFilter.value),
    }).then((responseStatisticsCollection) => {
      if (responseStatisticsCollection.status === 200) {
        setData(responseStatisticsCollection.data.dataResult);
        setIsLoading(false);
        setHandleValidate(false);
      }
    });
  };

  useEffect(
    () => {
      fetchData();
    },
    //eslint-disable-next-line
    [handleValidate],
  );

  //Collection By Flux and Filling Level By Time
  Object.keys(data).forEach((flux) => {
    dataSetsCollectionByFluxChart.push({
      label: flux,
      data: data[flux].monthlyDistribution.map((item) => ({
        x: item.time,
        y: item.countCollection,
        z: flux,
      })),
      backgroundColor: formattedWasteColor(flux),
      type: "bar",
    });

    dataSetsFillingLevelByTime.push({
      label: flux,
      data: data[flux].monthlyDistribution.map((item) => ({
        x: item.time,
        y: item.sumFillingLevel / item.countFillingLevel,
        z: flux,
      })),
      backgroundColor: formattedWasteColor(flux),
      type: "line",
      borderColor: formattedWasteColor(flux),
      tension: 0.1,
    });

    for (let i = 0; i < data[flux].monthlyDistribution.length; i++) {
      if (!labelsByTimeChart.includes(data[flux].monthlyDistribution[i].time)) {
        labelsByTimeChart.push(data[flux].monthlyDistribution[i].time);
      }
    }
    return null;
  });

  //Min
  dataSetsFillingLevelByFluxChart.push({
    label: "Min",
    data: Object.keys(data).map((flux) => ({
      x: flux,
      y: data[flux].min,
      z: "Min",
    })),
    backgroundColor: "blue",
    type: "line",
    borderColor: "blue",
    tension: 0.1,
  });
  //Average
  dataSetsFillingLevelByFluxChart.push({
    label: t("Average"),
    data: Object.keys(data).map((flux) => ({
      x: flux,
      y: data[flux].sumFillingLevel / data[flux].countFillingLevel,
      z: t("Average"),
    })),
    backgroundColor: "green",
    type: "line",
    borderColor: "green",
    tension: 0.1,
  });
  //Max
  dataSetsFillingLevelByFluxChart.push({
    label: "Max",
    data: Object.keys(data).map((flux) => ({
      x: flux,
      y: data[flux].max,
      z: "Max",
    })),
    backgroundColor: "red",
    type: "line",
    borderColor: "red",
    tension: 0.1,
  });
  //Label
  labelsByFluxChart = Object.keys(data);

  return (
    <div>
      {!isLoading ? (
        Object.keys(data).length > 0 ? (
          <section className="flex flex-col min-lg:flex-row my-2 ">
            <article className="min-lg:w-1/2  bg-white p-3 rounded-lg min-lg:mr-2 my-2">
              <h4 className="font-semibold">
                {t("Rotations by waste category")}
              </h4>
              <div className="modal-item-chart grid justify-items-center">
                <MultipleLineAndBarChart
                  stackedChart={true}
                  type="bar"
                  borderWidthChart={1}
                  labelsChart={labelsByTimeChart}
                  aspectRatioChart={1}
                  datasetsChart={dataSetsCollectionByFluxChart}
                  indexAxisChart="x"
                  maintainAspectRatioChart={false}
                  displayGrid={true}
                  displayTicks={true}
                  xAxisType={"time"}
                  reverseChart={false}
                  displayLegend={true}
                  displayTooltip={true}
                  messageLabelTooltip={t("Collections")}
                  unitValueChart=""
                  maxYAxis={null}
                />
              </div>
            </article>

            <article className="min-lg:w-1/2 bg-white p-3 rounded-lg min-lg:ml-2 my-2">
              <div className="flex flex-row justify-between">
                <h4 className="font-semibold">{t("Filling rate")}</h4>
                <div className="">
                  <span
                    onClick={() => {
                      setDisplayFillingByFlux(true);
                      setDisplayFillingByTime(false);
                    }}
                    className={`${displayFillingByFlux ? "px-4 py-1 cursor-pointer border-b-2 border-cyan text-cyan mx-2" : "px-4 py-1 cursor-pointer text-cyan bg-gainsboro rounded mx-2"}`}
                  >
                    {t("Waste category")}
                  </span>
                  <span
                    onClick={() => {
                      setDisplayFillingByFlux(false);
                      setDisplayFillingByTime(true);
                    }}
                    className={`${displayFillingByTime ? "px-4 py-1 cursor-pointer border-b-2 border-cyan text-cyan mx-2" : "px-4 py-1 cursor-pointer text-cyan bg-gainsboro rounded mx-2"}`}
                  >
                    {t("Time")}
                  </span>
                </div>
              </div>

              {displayFillingByFlux ? (
                <div className="modal-item-chart grid justify-items-center">
                  <MultipleLineAndBarChart
                    stackedChart={false}
                    type="line"
                    borderWidthChart={3}
                    labelsChart={labelsByFluxChart}
                    aspectRatioChart={1}
                    datasetsChart={dataSetsFillingLevelByFluxChart}
                    indexAxisChart="x"
                    maintainAspectRatioChart={false}
                    displayGrid={true}
                    displayTicks={true}
                    xAxisType={"category"}
                    reverseChart={false}
                    displayLegend={true}
                    displayTooltip={true}
                    messageLabelTooltip={t("of filling level")}
                    unitValueChart="%"
                    maxYAxis={100}
                  />
                </div>
              ) : null}

              {displayFillingByTime ? (
                <div className="modal-item-chart grid justify-items-center">
                  <MultipleLineAndBarChart
                    stackedChart={false}
                    type="line"
                    borderWidthChart={3}
                    labelsChart={labelsByTimeChart}
                    aspectRatioChart={1}
                    datasetsChart={dataSetsFillingLevelByTime}
                    indexAxisChart="x"
                    maintainAspectRatioChart={false}
                    displayGrid={true}
                    displayTicks={true}
                    xAxisType={"time"}
                    reverseChart={false}
                    displayLegend={true}
                    displayTooltip={true}
                    messageLabelTooltip={t("of filling level")}
                    unitValueChart="%"
                    maxYAxis={100}
                  />
                </div>
              ) : null}
            </article>
          </section>
        ) : (
          <section className="flex flex-col bg-white rounded-lg my-2 py-48 text-center text-cyan">
            <span class="fas fa-exclamation-triangle text-xl"></span>
            <span>{t("No collection for the current period")}</span>
          </section>
        )
      ) : (
        <div className="my-2">
          <Loading height={"55vh"} />
        </div>
      )}
    </div>
  );
};

export default StatisticsChartComponent;
