import React, { useEffect, useState } from "react";
import AppHeaderBar from "./AppHeaderBar";
import AppNavBar from "./AppNavBar";
import { checkIfMobile } from "../components/mobileHelpers";
import { useHistory } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";

const AppAccount = ({ setCookies, saveUser }) => {
  const [isMobile, setIsMobile] = useState(false);
  const history = useHistory();

  const handleLogout = async () => {
    await setCookies(null, null, null);
    await saveUser(null);
    localStorage.clear();
    sessionStorage.clear();
    history.push("/login");
  };

  const styles = {
    container: {
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    button: {
      display: "flex",
      alignItems: "center",
      padding: "10px 20px",
      backgroundColor: "#ff4d4f",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      fontSize: "16px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },
    buttonIcon: {
      marginRight: "10px",
      fontSize: "20px",
    },
    buttonText: {
      fontWeight: "bold",
    },
  };

  useEffect(() => {
    setIsMobile(checkIfMobile());

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  if (!isMobile) {
    return (
      <div className="mobileapp-container">
        <h1>Cette page est optimisée pour les appareils mobiles</h1>
        <p>
          Veuillez accéder à cette page depuis un appareil mobile pour une
          meilleure expérience.
        </p>
      </div>
    );
  }

  return (
    <div>
      <AppHeaderBar title="Akanthas" />
      <div className="mobileapp-container">
        <h1>Account</h1>
        <p>Language preference (soon)</p>
        <button style={styles.button} onClick={handleLogout}>
          <FaSignOutAlt style={styles.buttonIcon} />
          <span style={styles.buttonText}>Logout</span>
        </button>
      </div>
      <AppNavBar />
    </div>
  );
};

export default AppAccount;
